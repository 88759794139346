import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/helper';
import { isEmpty } from 'lodash';
import TermsAcceptancePopup from '../components/TermsAcceptancePopup';
export const ProtectedRoute = ({ children }) => {
	const { isLoggedInSuccess } = useAuth();
	const { pathname } = useLocation();

	return !isLoggedInSuccess && (isEmpty(localStorage.getItem('authToken')) && isEmpty(localStorage.getItem('authTokenAdmin'))) ?
		<Navigate to='/login' replace state={{ path: pathname }} /> :
		<>
			{<TermsAcceptancePopup />}
			{children}
		</>;
};