import styled from '@emotion/styled';
import theme from '../../../themes/base.json';

export const EditButton = styled.div`
    text-align: right;
    position: sticky;
    top: 0;
    background: ${theme.colors.white};
    padding: 10px 0;
    @media screen and (max-width: 991px) {
        padding: 10px 15px;
    }
    button {
        border-radius: 26.5px;
        background: ${theme.colors.black};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        padding: 12px 22px;
        width: 140px;
        svg {
            margin-right: 5px;
            width: 22px;
            height: 22px;    
        }
        &.save-btn {
            background: ${theme.colors.green};
            svg {
                margin-right: 12px;
            }
        }
        &.cancel {
            background: ${theme.colors.grey3};
            margin: 0 0 0 10px;
        }
    }
`;
export const InputInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position: relative;
    &.text-label {
        p {
            background: #FFFFFF;
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
        }
    }
    span.MuiTypography-root {
        margin-left: 10px;
    }
`;
export const FlashIconDiv = styled.div`
    width: 30px;
    height: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    span {
        margin-top: 3px;
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
`;
export const FlashSummary = styled.div`
    border-radius: 8px;
    border: 1px solid ${theme.colors.grey};
    padding: 0 37px 31px;
    &.padding_block {
        padding: 0 15px;
        span {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            @media (max-width: 1580px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        strong {
            font-size: 23px;
            color: ${theme.colors.primary};
            font-weight: 700;
        }
        .headingWrap {
            padding-left: 15px;
            padding-top: 3px;
            margin-bottom: 15px;
        }
        .MuiStack-root {
            margin-top: 10px;
            hr {
                margin: 0 20px 20px 20px;
                @media (max-width: 1700px) {
                    margin: 0 18px 20px 18px;
                }
                @media (max-width: 1580px) {
                    margin: 0 12px 20px 12px;
                }
                @media (max-width: 1400px) {
                    margin: 0 10px 20px 10px;
                }
                @media (max-width: 900px) {
                    border-bottom-width: 1px;
                }
            }
        }
        .MuiStack-root {
            @media(max-width: 1240px) {
                flex-wrap: wrap;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 0 15px 15px;
    }
`;

export const FlashHeading = styled.div`
    display: inline-flex;
    position: relative;
    margin-top: -20px;
    background: ${theme.colors.white};
    padding: 5px 10px;
    border-radius: 6px;
    h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.6px;
        width: calc(100% - 31px);
    }
`;
export const FlashContent = styled.div`
    p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: ${theme.colors.black};
        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
        }
        .green {
            color: rgba(8, 170, 43, 0.96);
        }
    }
    h4 {
        span {
            color: ${theme.colors.primary};
        }
    }
    table {
        @media screen and (max-width: 991px) {
            white-space: nowrap;
        }
        thead {
            tr {
                th {
                    font-size: 16px;
                    font-weight: 700;
                    background: rgba(0, 0, 0, 0.06);
                    border: 1px solid ${theme.colors.grey};
                    padding: 10px 12px;
                    line-height: normal;
                }
            }
        }
        tbody {
            tr {
                td {
                    border: 1px solid ${theme.colors.grey};
                    background: ${theme.colors.white};
                    padding: 10px 12px;
                    color: ${theme.colors.black};
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                    span {
                        font-weight: 400;
                    }
                    input {
                        padding: 10.5px 10px 7.5px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.3px;
                        color: ${theme.colors.black};
                    }
                    
                }
                td.grey{
                    background: rgba(0, 0, 0, 0.06);
                }
            }
        }
        tfoot {
            tr {
                td {
                    border: 1px solid ${theme.colors.grey};
                    border-bottom: none;
                    background: ${theme.colors.black};
                    padding: 10px 12px;
                    color: ${theme.colors.white};
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
        strong {
            font-weight: 700;
        }
    }
    ul {
        padding: 0;
        list-style-type: disc;
        li {
            display: block;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.4px;
            color: ${theme.colors.black};
            @media screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 22px;
            }
            &:before {
                content: "";
                width: 5px;
                height: 5px;
                background: ${theme.colors.black};
                border-radius: 100px;
                position: absolute;
                left: 0;
                margin-top: 4px;
            }
            strong {
                color: ${theme.colors.primary};
            }
        }
    }
    span {
        &.MuiTypography-button {
            border-radius: 3px;
            background: ${theme.colors.primary};
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            color: ${theme.colors.white};
            text-transform: none;
            padding: 8px;
            margin-top: 50px;
            display: inline-block;
            line-height: normal;
            @media screen and (max-width: 991px) {
                margin-top: 20px;
            }
        }
    }
    .stepOneHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 991px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    .stepOneButton {
        @media (max-width: 991px) {
            margin-left: auto;
            padding: 0;
            margin-bottom: 10px;
        }
    }
`;
export const StatisticsBlock = styled.div`
    margin-bottom: 20px !important;
    h5 {
        color: ${theme.colors.primary};
        font-weight : bold;
        font-size: 20px;
        @media(max-width: 1240px) {
            font-size: 18px;
        }
    }
    &:last-child {
        h5{
            color: ${theme.colors.lightGreen};
        }
    }
    span {
        font-size: 18px;
        line-height: 22px;
    }
`;
export const StatisticsPriceBlock = styled.div`
    margin-bottom: 20px !important;
    h5 {
        color: ${theme.colors.primary};
        font-weight : bold;
        font-size: 24px;
        @media(max-width: 1240px) {
            font-size: 18px;
        }
    }
    &:last-child {
        h5{
            color: ${theme.colors.lightGreen};
        }
        h5.greyPrice{
            color: ${theme.colors.grey};
        }
    }
    span {
        font-size: 18px;
        line-height: 22px;
    }
    .MuiInputBase-root{
        height: 35px;
        line-height: 35px;
        padding: 10px 0 0 0;
        margin-right: 5px;
    }
    .MuiInputBase-input{
        max-width: 80px;
        width: 100%;
        color: ${theme.colors.lightGreen};
        border-radius: 10px;
        font-size: 23px;
        line-height: 32px;
        font-weight: 700;
        border-color: #BBBBBB;
        text-align: end;
        padding: 0 5px;
        margin-right: 5px;
    }
`;
export const IncludeVat = styled.div`
    text-align : right;
    margin-bottom : 5px;
    @media(max-width: 767px) {
        margin-bottom: 35px;
    }
    .MuiFormControlLabel-label{
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.02em;
    }
    .MuiButtonBase-root {
        width: 25px;
        height: 25px;
        border-radius: 7px;
        padding: 5px 2px;
        border: 1px solid ${theme.colors.grey};
        margin-right: 16px;
        &.Mui-checked {
            background-color: ${theme.colors.black}
        }
        .MuiSvgIcon-root {
            opacity: 0;
        }
    }
`;
export const ActionButtons = styled.div`
    margin: 0 0 44px 0 !important;
    text-align: right;
    top: 0;
    background: ${theme.colors.white};
    padding: 10px 0;
    @media screen and (max-width: 991px) {
        padding: 10px 15px;
    }
    button {
        border-radius: 26.5px;
        background: ${theme.colors.black};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        padding: 12px 22px;
        svg {
            margin-right: 5px;
            width: 22px;
            height: 22px;    
        }
        &.modify-price-btn{
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
            svg {
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
        }
        &.offer-btn{
            margin-left: 15px;
            background: ${theme.colors.primary};
            svg {
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
        }
        &.share-btn{
            margin-right: 15px;
            background: ${theme.colors.primary};
            svg {
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
        }
        &.save-btn {
            background: ${theme.colors.green};
            svg {
                margin-right: 12px;
            }
        }
        &.cancel {
            background: ${theme.colors.grey3};
            margin: 0 0 0 10px;
        }
    }
`;
export const InviteButton = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    @media screen and (max-width: 991px) {
        padding: 10px 15px;
    }
    a.MuiButton-contained {
        border-radius: 6px;
        background: ${theme.colors.primary};
        color: ${theme.colors.white};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
        text-transform: none;
        border-radius: 6px;
        font-size: 16px;
        text-align: center;
        svg{
            width: 15px;
            height: 16px;
            margin-left: 10px;
            fill: #89878A;
        }
    }
`;
export const TableContent = styled.div`
    max-width: 950px;
    border-radius: 21px;
    border: 1px solid ${theme.colors.grey};
    margin-top: 10px;
    table.simpleTable {
        @media screen and (max-width: 991px) { 
            white-space: nowrap;
        }
        thead {
            th {
                color: ${theme.colors.black};
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.4px;
                text-align: center;
                border: none;
                padding: 15px 20px 15px;
                border-bottom: 1px solid ${theme.colors.grey1};
            }
        }
        tbody {
            tr {
                &:first-of-type td {
                    padding-top: 17px;
                }
                &:last-child td {
                    padding-bottom: 17px;
                }
                td {
                    border: none;
                    padding: 15px;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    color: ${theme.colors.black};
                    @media screen and (max-width: 767px) { 
                        font-size: 16px;
                    }
                    input {
                        padding: 10.5px 10px 7.5px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.3px;
                        color: ${theme.colors.black};
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                    fieldset {
                        border: 1px solid ${theme.colors.grey};
                        border-radius: 6px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
    
`;