import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { TextField, Container, InputAdornment, Typography, Button, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogin } from '../../../redux/auth';
import HeaderComponent from '../../../components/HeaderComponent';
import { Title, UserForm, ButtonText, MainBox } from './styled.js';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import '../../../../src/locales/i18n';
import { useAuth } from '../../../utils/helper.js';
import { UserIcon, LockIcon } from '../../../assets/icon';

const Login = () => {
	const useAuthContext = useAuth();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [failOpen, setFailOpen] = useState(false);
	const rememberMeRef = useRef(false);
	const [rememberMe, setRememberMe] = useState(false);
	const { loginErrorMessage } = useSelector(state => state.auth);
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFailOpen(false);
	};
	const [hideShowValue, setValues] = useState({
		password: '',
		showPassword: false
	});
	const handleClickShowPassword = () => {
		setValues({
			...hideShowValue,
			showPassword: !hideShowValue.showPassword,
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Formik and YUP validation
	const initialValues = {
		email: localStorage.getItem('email') || '',
		password: localStorage.getItem('password') || '',
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues,
		validationSchema: Yup.object({
			password: Yup.string()
				.required('password Required'),
			email: Yup.string()
				.email('Invalid email address')
				.required('email Required')
		}),
		onSubmit: values => {
			if (rememberMeRef.current.checked) {
				localStorage.setItem('email', values.email);
				localStorage.setItem('password', values.password);
			} else {
				localStorage.removeItem('email');
				localStorage.removeItem('password');
			}
			dispatch(handleLogin({ body: values, useAuthContext }));
		}
	});
	useEffect(() => {
		if (!isEmpty(loginErrorMessage)) {
			setFailOpen(true);
		}
	}, [loginErrorMessage]);
	useEffect(() => {
		if (localStorage.getItem('email') && localStorage.getItem('password')) {
			setRememberMe(true);
		}
	}, []);
	return (
		<>
			<HeaderComponent />
			<Container component="main">
				<MainBox>
					<Title>
						<Typography variant="h1" component="h1">{t('Login.Title')}</Typography>
					</Title>
					<UserForm>
						<Formik>
							<Form autoComplete='off'>
								<TextField
									error={(touched.email && errors.email) ? true : false}
									name="email"
									id="email"
									type="email"
									placeholder='info@quotabus.com'
									value={values.email}
									helperText={touched.email && errors.email}
									onChange={handleChange}
									onBlur={handleBlur}
									margin="dense"
									required
									sx={{ width: '100%' }}
									InputProps={{
										startAdornment: <InputAdornment position="start"><UserIcon /></InputAdornment>,
									}}
								/>
								<TextField
									error={(touched.password && errors.password) ? true : false}
									name="password"
									id="password"
									placeholder='**************'
									type={hideShowValue.showPassword ? 'text' : 'password'}
									helperText={touched.password && errors.password}
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="false"
									margin="dense"
									sx={{ width: '100%' }}
									InputProps={{
										startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
										endAdornment: <InputAdornment position="end">
											<Button
												disableRipple
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{hideShowValue.showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
											</Button>
										</InputAdornment>,
									}}
									required
								/>
								<FormControlLabel control={<Checkbox inputRef={rememberMeRef} checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />} label={t('Login.RememberMe')} />
								<Button
									onClick={handleSubmit}
									type="submit"
									variant="contained"
								>
									{t('Login.LoginIn')}
								</Button>
								<ButtonText>
									<Button
										variant="text"
										onClick={() => navigate('/forgot-password')}
									>
										{t('Login.ForgotPassword')}
									</Button>
								</ButtonText>
							</Form>
						</Formik>
					</UserForm>
					<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={failOpen} autoHideDuration={5000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
							{!isEmpty(loginErrorMessage) && <Typography>{loginErrorMessage}</Typography>}
						</Alert>
					</Snackbar>
				</MainBox>
			</Container>
		</>
	);
};

export default Login;
