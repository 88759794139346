import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import * as Yup from 'yup';
import { Formik, Form, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Title, UserForm, MainBox } from './styled.js';
import HeaderComponent from '../../../components/HeaderComponent';
import { LockIcon } from '../../../assets/icon';
import useQuery from '../../../utils/useQuery';
import { ResetPassword } from '../../../redux/auth/index.js';
import { resetResetPassword } from '../../../redux/auth/slice.js';

const ResetPasswordPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { email, token } = useQuery();
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRetypePassword, setShowRetypePassword] = useState(false);
	const [failOpen, setFailOpen] = useState(false);
	const { resetPasswordDetail, resetPasswordError } = useSelector(state => state.auth);
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFailOpen(false);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	// Formik and YUP validation
	const initialValues = {
		token: token,
		newpassword: '',
		retypepassword: ''
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues,
		validationSchema: Yup.object({
			newpassword: Yup.string()
				.required(t('General.PasswordRequired'))
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
					t('General.PasswordCondition')
				),
			retypepassword: Yup.string()
				.required(t('General.RetypePasswordRequired'))
				.oneOf([Yup.ref('newpassword'), null], t('General.PasswordMismatch')),
		}),
		onSubmit: values => {
			const payload = {
				token: values.token,
				password: values.newpassword,
				confirm_password: values.retypepassword
			};
			dispatch(ResetPassword(payload));
		}
	});
	useEffect(() => {
		if (!isEmpty(resetPasswordDetail)) {
			navigate('/success-reset-password');
		}
		if (!isEmpty(resetPasswordError)) {
			setFailOpen(true);
		}
	}, [resetPasswordDetail, resetPasswordError]);
	useEffect(() => {
		return () => {
			dispatch(resetResetPassword());
		};
	}, []);
	return (
		<>
			<HeaderComponent />
			<Container>
				<MainBox>
					<Title>
						<Typography variant="h1" component="h1">{t('ResetPassword.Title')}</Typography>
						<Typography variant="h1" component="p">{t('ResetPassword.EmailInfo', { email })}</Typography>
					</Title>
					<UserForm>
						<Formik>
							<Form>
								<TextField
									error={(touched.newpassword && errors.newpassword) ? true : false}
									name="newpassword"
									id="newpassword"
									placeholder={t('ResetPassword.NewPassword')}
									type={showNewPassword ? 'text' : 'password'}
									helperText={touched.newpassword && errors.newpassword}
									value={values.newpassword}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="false"
									margin="dense"
									sx={{ width: '100%' }}
									InputProps={{
										startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
										endAdornment: <InputAdornment position="end">
											<Button
												disableRipple
												onClick={() => setShowNewPassword(!showNewPassword)}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
											</Button>
										</InputAdornment>,
									}}
									required
								/>
								<TextField
									error={(touched.retypepassword && errors.retypepassword) ? true : false}
									name="retypepassword"
									id="retypepassword"
									placeholder={t('ResetPassword.RetypePassword')}
									type={showRetypePassword ? 'text' : 'password'}
									helperText={touched.retypepassword && errors.retypepassword}
									value={values.retypepassword}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="false"
									margin="dense"
									sx={{ width: '100%' }}
									InputProps={{
										startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
										endAdornment: <InputAdornment position="end">
											<Button
												disableRipple
												onClick={() => setShowRetypePassword(!showRetypePassword)}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showRetypePassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
											</Button>
										</InputAdornment>,
									}}
									required
								/>
								<Button
									onClick={handleSubmit}
									type="submit"
									variant="contained">
									{t('ResetPassword.ResetPasswordButton')}
								</Button>
							</Form>
						</Formik>
					</UserForm>
					<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={failOpen} autoHideDuration={5000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
							{!isEmpty(resetPasswordError) && resetPasswordError?.token.map((error, index) => {
								return (<Typography key={index}>{error}</Typography>);
							})}
						</Alert>
					</Snackbar>
				</MainBox>
			</Container >
		</>
	);
};

export default ResetPasswordPage;
