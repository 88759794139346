import styled from '@emotion/styled';
import theme from '../../themes/base.json';


export const DetailPage = styled.div`
    @media screen and (max-width: 991px) {
        padding: 20px 24px;
    }   
`;
export const HeadPart = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 26px 0;
    h2 {
        font-size: 25px;
        font-weight: 700;
        letter-spacing: -0.5px;
        color: ${theme.colors.black};
        line-height: normal;
    }
`;

export const RightPart = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    input {
        padding: 13px 16px 8px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
    }
    fieldset {
        border: 1px solid ${theme.colors.grey};
        border-radius: 8px;
    }
    form {
        display: flex;
        align-items: flex-end;
    }
`;

export const DateSelect = styled.div`
    display: flex;
    flex-wrap: wrap;
    .MuiTextField-root {
        input {
            padding-right: 0;
        }
        .MuiInputBase-root {
            flex-direction: row-reverse;
        }
        .MuiButtonBase-root {
            padding: 0;
            margin-top: 3px;
        }
    }
`;

export const DateBox = styled.div`
    width: 175px;
    margin-left: 15px;
    label {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
        color: ${theme.colors.black};
        margin: 0 0 7px 0;
    }
    button {
        position: unset;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: transparent;
        }
    }
`;

export const TableContent = styled.div`
    border-radius: 21px;
    border: 1px solid ${theme.colors.grey};
    margin-bottom: 15px;
    table {
        thead {
            th {
                color: ${theme.colors.black};
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                border: none;
                padding: 15px 17px 15px;
                border-bottom: 1px solid ${theme.colors.grey1};
            }
        }
        tbody {
            tr {
                &:first-of-type td {
                    padding-top: 15px;
                }
                &:last-child td {
                    padding-bottom: 15px;
                }
                td {
                    border: none;
                    padding: 15px 20px;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: ${theme.colors.black}
                    input {
                        padding: 10.5px 10px 7.5px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.3px;
                        color: ${theme.colors.black};
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                    fieldset {
                        border: 1px solid ${theme.colors.grey};
                        border-radius: 6px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                    svg {
                        width: 26px;
                        height: 26px;
                        margin: 0 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    button.MuiButton-contained {
        border-radius: 5px;
        background: ${theme.colors.primary};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 12px 16px;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
        text-transform: none;
        margin-left: 60px;
        margin-top: 33px;
        &:before {
            content: "+";
            font-size: 50px;
            line-height: 30px;
            position: relative;
            top: 3px;
            margin-right: 15px;
        }
    }
`;


