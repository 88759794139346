import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import theme from '../../themes/base.json';

export const StepNumber = styled.div`
    // border-radius: 8px;
    // border: 1px solid ${theme.colors.grey};
    padding-bottom: 15px;
    * {
        box-sizing: border-box;
    }
    &.padding_block {
        padding: 0 15px;
        span {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            @media (max-width: 1580px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        strong {
            font-size: 23px;
            color: ${theme.colors.primary};
            font-weight: 700;
        }
        .headingWrap {
            padding-left: 15px;
            padding-top: 3px;
            margin-bottom: 15px;
        }
        .MuiStack-root {
            margin-top: 10px;
            hr {
                margin: 0 20px 20px 20px;
                @media (max-width: 1700px) {
                    margin: 0 18px 20px 18px;
                }
                @media (max-width: 1580px) {
                    margin: 0 12px 20px 12px;
                }
                @media (max-width: 1400px) {
                    margin: 0 10px 20px 10px;
                }
                @media (max-width: 900px) {
                    border-bottom-width: 1px;
                }
            }
        }
        .MuiStack-root {
            @media(max-width: 1240px) {
                flex-wrap: wrap;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 0 0px 15px;
    }
`;
export const StepTitle = styled.div`
    cursor: pointer;
    padding : 10px 20px;
    border-radius: 50px;
    border: 1px solid ${theme.colors.grey};
    width :100%;
`;
export const StepHeading = styled.div`
    display: inline-flex;
    position: relative;
    margin-top: -20px;
    background: ${theme.colors.white};
    padding: 5px 10px;
    // border-radius: 6px;
    h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.6px;
        width: calc(100% - 31px);
    }
`;

export const Number = styled.div`
    background: ${theme.colors.primary};
    width: 30px;
    height: 30px;
    border-radius: 100px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${theme.colors.white};
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    @media (max-width: 767px) {
        width: 25px;
        height: 25px;
        font-size: 18px;
    }
    span {
        margin-top: 3px;
    }
`;
export const FlashIconDiv = styled.div`
    width: 30px;
    height: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    span {
        margin-top: 3px;
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
`;

export const StepContent = styled.div`
    margin : 10px;
    padding : 20px 20px 30px 20px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.grey};
    p {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: ${theme.colors.black};
        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
        }
        .green {
            color: rgba(8, 170, 43, 0.96);
        }
    }
    h4 {
        span {
            color: ${theme.colors.primary};
        }
    }
    table.tripTable {
        @media screen and (max-width: 991px) {
            white-space: nowrap;
        }
        thead {
            tr {
                th {
                    font-size: 16px;
                    font-weight: 700;
                    background: rgba(0, 0, 0, 0.06);
                    border: 1px solid ${theme.colors.grey};
                    padding: 10px 12px;
                    line-height: normal;
                }
            }
        }
        tbody {
            tr {
                td {
                    border: 1px solid ${theme.colors.grey};
                    background: ${theme.colors.white};
                    padding: 10px 12px;
                    color: ${theme.colors.black};
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                    span {
                        font-weight: 400;
                    }
                    input {
                        padding: 10.5px 10px 7.5px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.3px;
                        color: ${theme.colors.black};
                    }
                    
                }
                td.grey{
                    background: rgba(0, 0, 0, 0.06);
                }
            }
        }
        tfoot {
            tr {
                td {
                    border: 1px solid ${theme.colors.grey};
                    border-bottom: none;
                    background: ${theme.colors.black};
                    padding: 10px 12px;
                    color: ${theme.colors.white};
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
        strong {
            font-weight: 700;
        }
    }
    ul {
        padding: 0;
        list-style-type: disc;
        li {
            display: block;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.4px;
            color: ${theme.colors.black};
            @media screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 22px;
            }
            &:before {
                content: "";
                width: 5px;
                height: 5px;
                background: ${theme.colors.black};
                border-radius: 100px;
                position: absolute;
                left: 0;
                margin-top: 4px;
            }
            strong {
                color: ${theme.colors.primary};
            }
        }
    }
    span {
        &.MuiTypography-button {
            border-radius: 3px;
            background: ${theme.colors.primary};
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            color: ${theme.colors.white};
            text-transform: none;
            padding: 8px;
            margin-top: 50px;
            display: inline-block;
            line-height: normal;
            @media screen and (max-width: 991px) {
                margin-top: 20px;
            }
        }
    }
    .stepOneHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 991px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    .stepOneButton {
        @media (max-width: 991px) {
            margin-left: auto;
            padding: 0;
            margin-bottom: 10px;
        }
    }
    .steplink{
        color : ${theme.colors.primary};
        font-weight: bold;
        text-decoration-color : ${theme.colors.primary};
    }
`;


export const AccordionButton = styled(IconButton)`
  color: #000;
  padding: 0;
  &:hover {
    background: transparent;
  }
  svg {
    width: 64px;
    height: 65px;
    @media (max-width: 767px) {
        width: 35px;
        height: 35px;
    }
  }
`;


export const AccordionTitle = styled(Typography)`
  color: #000;
  font-weight: bold;
  line-height: 1; 
  font-size: 30px;
  padding-top: 4px;
  max-width: calc(100% - 40px);
  @media (max-width: 767px) {
    font-size: 18px;
    max-width: calc(100% - 35px);
  }
`;
