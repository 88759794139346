import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
    margin-top: 7px;
    margin-bottom: 12px;
    h2 {
        font-size: 25px;
        font-weight: 700;
        letter-spacing: -0.5px;
        color: ${theme.colors.black};
        line-height: normal;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        @media screen and (max-width: 899px) {
            justify-content: flex-start;
        }
        li {
            padding: 0;
            width: auto;
            margin-left: 20px;
            @media screen and (max-width: 899px) {
                margin-right: 20px;
                margin-top: 10px;
                margin-left: 0;
            }
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.32px;
                margin-left: 10px;
            }
        }
    }
`;

export const GrayDot = styled.div`
    width: 30.196px;
    height: 12px;
    border-radius: 17px;
    background: ${theme.colors.grey1};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -4px;
`;
export const PurpleDot = styled.div`
    width: 30.196px;
    height: 12px;
    border-radius: 17px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -4px;
`;

export const SeasonalityContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 21px;
    border: 1px solid ${theme.colors.grey};
    padding: 27px 30px 21px;
    @media screen and (max-width: 767px) {
        padding: 27px 15px 21px;
    }
`;

export const CheckboxList = styled.div`
    width: 75%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    .MuiFormGroup-root {
        flex-direction: unset;
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
    }
    span.MuiCheckbox-root {
        display: none;
    }
    label.MuiFormControlLabel-root {
        margin: 0 12px 12px 0;
        span.Mui-disabled {
            color: #000;
        }
        .Mui-checked {
            + span.Mui-disabled {
                color: #fff;
            }
            + span.MuiFormControlLabel-label {
                background: ${theme.colors.primary};
                color: ${theme.colors.white};
            }
        }
    }
    span.MuiFormControlLabel-label {
        border-radius: 100px;
        background: ${theme.colors.grey1};
        padding: 10.5px 12px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.3px;
        color: ${theme.colors.black};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        min-width: 71px;
        text-align: center;
    }
`;

export const SeasonInfo = styled.div`
    width: 25%;
    text-align: center;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
    }
    h5 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        color: ${theme.colors.black};
        text-align: center;
        margin: 0 0 18px;
        @media screen and (max-width: 767px) {
            margin: 0 0 8px;
        }
    }
    span {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
    }
    input {
        padding: 10.5px 10px 7.5px;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.3px;
        color: #000;
    }
    fieldset {
        border: 1px solid ${theme.colors.grey};
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

