import React from 'react';
import { Chip } from '@mui/material';
import { t } from 'i18next';

const BusPartnerFooterComponent = () => {
	const handleClick = () => {
		window.open('https://quotabus.com/', '_blank', 'noopener,noreferrer');
	};
	const label = (
		<span>
			{t('Footer.PoweredBy')}{' '}
			<a>
				{`${t('General.Quotabus')}`}<span>.</span>
			</a>
		</span>
	);
	return (
		<div className="footer-powered">
			<Chip
				label={label}
				color="primary"
				clickable
				onClick={handleClick}
				style={{ cursor: 'pointer' }}
				variant="outlined"
			/>
		</div>
	);
};

export default BusPartnerFooterComponent;
