import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { isEmpty, has } from 'lodash';
import Editor from 'react-simple-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { EditButton, HeadPart, CustomEmailBox } from './styled';
import { CrossIcon, EditIcon, SaveIcon } from '../../assets/icon';
import { getMailTemplate, updateMailTemplate } from '../../redux/auth';

const EmailPersonalization = ({ language }) => {
	const { mailTemplate } = useSelector(state => state.auth);
	const [mailBody, setMailBody] = useState(mailTemplate?.template);
	const [isClassActive, setClassActive] = useState(false);
	const dispatch = useDispatch();
	const handleEdit = (e) => {
		e.preventDefault();
		setClassActive(!isClassActive);
	};
	useEffect(() => {
		dispatch(getMailTemplate({ 'lng' : language }));
	}, []);
	useEffect(() => {
		if (!isEmpty(mailTemplate) && has(mailTemplate, language)) {
			setMailBody(mailTemplate[language].template);
		}
	}, [mailTemplate]);

	const handleOnChange = (e) => {
		setMailBody(e.target.value);
	};
	const handleSave = () => {
		dispatch(updateMailTemplate({ template: mailBody, 'lng' : language }));
		setClassActive(!isClassActive);
	};
	return (
		<>
			<HeadPart>
				<div>
					<Typography component='h2'>{t(`Profile.${language}.CustomEmail`)}</Typography>
					<Typography component='h3' color='danger'>{t(`Profile.${language}.ImpNote`)} <span>{t(`Profile.${language}.Example`)}</span></Typography>
				</div>
				<EditButton>
					{!isClassActive ?
						<Button variant='contained' onClick={handleEdit}><EditIcon />{t(`Profile.${language}.Edit`)}</Button>
						:
						<div>
							<Button variant='contained' onClick={handleSave} className='save-btn'><SaveIcon />{t(`Profile.${language}.Save`)}</Button>
							<Button variant='contained' onClick={() => {
								setMailBody(mailTemplate[language]?.template);
								setClassActive(!isClassActive);
							}} className='cancel'><CrossIcon />{t(`Profile.${language}.Cancel`)}</Button>
						</div>
					}
				</EditButton>
			</HeadPart>
			{!isEmpty(mailTemplate) && has(mailTemplate, language) &&
				<>
					{
						!isClassActive ?
							<CustomEmailBox>
								<div dangerouslySetInnerHTML={{ __html: mailTemplate[language]?.template_with_format }} />
							</CustomEmailBox >
							:
							<Editor value={mailBody} onChange={handleOnChange} >
							</Editor>
					}
				</>
			}
		</>
	);
};

export default EmailPersonalization;
