import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const SuccessNotification = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background: rgba(212, 233, 231, 1);
    position: fixed;
    top: 10px;
    left: 265px;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 410px;
    z-index: 9;
    @media screen and (max-width: 991px) {
        left: 0;
        right: 0;
        width: calc(100% - 40px);
        max-width: 100%;
    }
    span {
        width: calc(100% - 41px);
        padding-left: 11px;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.3px;
    }
`;

export const CheckMark = styled.div`
    width: 30px;
    height: 30px;
    background: ${theme.colors.green};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
