import React from 'react';
import { t } from 'i18next';
import { Typography } from '@mui/material';
import { MainPricing, PrcingBox } from './styled';
import { useSelector } from 'react-redux';
import { formatItalianNumber } from '../../utils/helper';
const PricingComponent = () => {
	const { requestHistoryDetail } = useSelector(state => state.reqhistory);

	return (
		<>
			<MainPricing>
				<PrcingBox>
					<Typography component="span">{formatItalianNumber(requestHistoryDetail?.number_of_requests)}</Typography>
					<Typography component="p">{t('Requests.NoQuote')}</Typography>
				</PrcingBox>
				<PrcingBox>
					<Typography component="span">{formatItalianNumber(requestHistoryDetail?.value_requests_created)} €</Typography>
					<Typography component="p">{t('Requests.TotalQuote')}</Typography>
				</PrcingBox>
				<PrcingBox>
					<Typography component="span">{formatItalianNumber(requestHistoryDetail?.average_value_requests)} €</Typography>
					<Typography component="p">{t('Requests.AverageQuote')}</Typography>
				</PrcingBox>
			</MainPricing>
		</>
	);
};

export default PricingComponent;
