import { createSlice } from '@reduxjs/toolkit';
import { ResetPassword, acceptTerms, forgotPassword, getMailTemplate, handleAdminLogOut, handleLogOut, handleLogin, handleSignup, updateMailTemplate, paymentTermsConditionAccept, getUserDetails, createConnectLink, changeStripeStatus } from '../auth';

const initialState = {
	isLoggedIn: false,
	error: false,
	loading: false,
	loginResponse: {},
	busPartner: {},
	userDetails: {},
	forgotPasswordDetail: {},
	forgotPasswordError: '',
	resetPasswordDetail: {},
	resetPasswordError: '',
	acceptTermsDetail: {},
	acceptTermsError: '',
	mailTemplateUpdateError: '',
	paymentTermsAccept: {},
	paymentTermsAcceptError: '',
	userDetailsError: '',
	connectLinkloading:false,
	connectLink: {},
	connectLinkError: '',
	changeStripeStatusError:'',
	changeStripeStatusFlag : null
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: () => initialState,
		loggedUserInfo: (state, { payload }) => {
			state.userDetails = payload;
			state.isLoggedIn = true;
			if (payload && payload?.role == 'BusPartner') {
				state.busPartner = payload;
				localStorage.setItem('authToken', payload.token);
			}
			else {
				state.busPartner = {};
				localStorage.setItem('authTokenAdmin', payload.token);
			}
		},
		logout: (state) => {
			state.isLoggedIn = false;
		},
		setBusPartner: (state, { payload }) => {
			state.busPartner = payload;
		},
		resetForgotPassword: (state) => {
			state.loading = false;
			state.forgotPasswordDetail = {};
			state.forgotPasswordError = '';
		},
		resetResetPassword: (state) => {
			state.loading = true;
			state.resetPasswordDetail = {};
			state.resetPasswordError = '';
		},
		resetPaymentTerms: (state) => {
			state.paymentTermsAccept = {};
			state.paymentTermsAcceptError = '';
		},
		resetConnectLink: (state) => {
			state.connectLinkloading = false;
			state.connectLink = {};
			state.connectLinkError = '';
		},
		resetAllErrors: (state) => {
			state.changeStripeStatusFlag = null;
			state.changeStripeStatusError = '';
			state.paymentTermsAcceptError = '';
			state.connectLinkError = '';
			state.userDetailsError = '';
		}
	},
	extraReducers: (builder) => {
		builder.addCase(handleLogin.pending, (state) => {
			state.loading = true;
			state.loginErrorMessage = '';
		});
		builder.addCase(handleLogin.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.isLoggedIn = true;
			state.loginResponse = payload.response;
			state.loginSuccessMessage = payload.response.data.message;
			state.loginErrorMessage = '';
			state.userDetails = payload.response.data.data;
			if (payload.response.data.data.role == 'BusPartner') {
				state.busPartner = payload.response.data.data;
				localStorage.setItem('authToken', payload.response.data.data.token);
			}
			else {
				state.busPartner = {};
				localStorage.setItem('authTokenAdmin', payload.response.data.data.token);
			}
		});
		builder.addCase(handleLogin.rejected, (state, err) => {
			state.error = true;
			state.loading = false;
			state.isLoggedIn = false;
			state.loginErrorMessage = err.payload?.message;
		});

		builder.addCase(handleLogOut.fulfilled, () => initialState);
		builder.addCase(handleAdminLogOut.fulfilled, () => initialState);

		builder.addCase(handleSignup.pending, (state) => {
			state.loading = true;
			state.loginErrorMessage = '';
		});
		builder.addCase(handleSignup.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.loginResponse = payload.response;
			state.loginSuccessMessage = payload.response.data.message;
			state.loginErrorMessage = '';
			state.userDetails = payload.response.data.data;
			localStorage.setItem('authToken', payload.response.data.data.token);
		});
		builder.addCase(handleSignup.rejected, (state, err) => {
			state.error = true;
			state.loading = false;
			state.loginErrorMessage = err.payload.message;
		});

		builder.addCase(forgotPassword.pending, (state) => {
			state.loading = true;
			state.forgotPasswordDetail = {};
			state.forgotPasswordError = '';
		});
		builder.addCase(forgotPassword.fulfilled, (state, action) => {
			state.loading = false;
			state.forgotPasswordDetail = action.payload?.response.data;
		});
		builder.addCase(forgotPassword.rejected, (state, err) => {
			state.loading = false;
			state.forgotPasswordError = err?.payload?.message;
		});

		builder.addCase(ResetPassword.pending, (state) => {
			state.loading = true;
			state.resetPasswordDetail = {};
			state.resetPasswordError = '';
		});
		builder.addCase(ResetPassword.fulfilled, (state, action) => {
			state.loading = false;
			state.resetPasswordDetail = action.payload?.response.data;
		});
		builder.addCase(ResetPassword.rejected, (state, err) => {
			state.loading = false;
			state.resetPasswordError = err?.payload?.message;
		});

		builder.addCase(acceptTerms.pending, (state) => {
			state.loading = true;
			state.acceptTermsDetail = {};
			state.acceptTermsError = '';
		});
		builder.addCase(acceptTerms.fulfilled, (state, action) => {
			state.loading = false;
			state.acceptTermsDetail = action.payload?.response.data;
			state.userDetails = { ...state.userDetails, terms_accepted: true };
		});
		builder.addCase(acceptTerms.rejected, (state, err) => {
			state.loading = false;
			state.acceptTermsError = err?.payload?.message;
		});

		builder.addCase(getMailTemplate.pending, (state) => {
			state.loading = true;
			state.mailTemplate = {};
			state.mailTemplateError = '';
		});
		builder.addCase(getMailTemplate.fulfilled, (state, action) => {
			state.loading = false;
			state.mailTemplate[action.meta.arg.lng] = action.payload?.response.data.data;
		});
		builder.addCase(getMailTemplate.rejected, (state, err) => {
			state.loading = false;
			state.mailTemplateError = err?.payload?.message;
		});

		builder.addCase(updateMailTemplate.pending, (state, action) => {
			state.loading = true;
			state.mailTemplate[action.meta.arg.lng] = {};
			state.mailTemplateUpdateError = '';
		});
		builder.addCase(updateMailTemplate.fulfilled, (state, action) => {
			state.loading = false;
			state.mailTemplate[action.meta.arg.lng] = action.payload?.response.data.data;
		});
		builder.addCase(updateMailTemplate.rejected, (state, err) => {
			state.loading = false;
			state.mailTemplateUpdateError = err?.payload?.message;
		});

		builder.addCase(paymentTermsConditionAccept.pending, (state) => {
			state.loading = true;
			state.paymentTermsAccept = {};
			state.paymentTermsAcceptError = '';
		});
		builder.addCase(paymentTermsConditionAccept.fulfilled, (state, action) => {
			state.loading = false;
			state.paymentTermsAccept = action.payload?.response.data;
		});
		builder.addCase(paymentTermsConditionAccept.rejected, (state, err) => {
			state.loading = false;
			state.paymentTermsAcceptError = err?.payload?.message;
		});

		builder.addCase(getUserDetails.pending, (state) => {
			state.loading = true;
			state.userDetailsError = '';
		});
		builder.addCase(getUserDetails.fulfilled, (state, action) => {
			state.loading = false;
			state.busPartner = {
				token: state.busPartner?.token,
				...action.payload?.response.data.data
			};
		});
		builder.addCase(getUserDetails.rejected, (state, err) => {
			state.loading = false;
			state.userDetailsError = err?.payload?.message;
		});

		builder.addCase(createConnectLink.pending, (state) => {
			state.connectLinkloading = true;
			state.connectLink = {};
			state.connectLinkError = '';
		});
		builder.addCase(createConnectLink.fulfilled, (state, action) => {
			state.connectLinkloading = false;
			state.connectLink = action.payload?.response.data.data;
		});
		builder.addCase(createConnectLink.rejected, (state, err) => {
			state.connectLinkloading = false;
			state.connectLinkError = err?.payload?.message;
		});

		builder.addCase(changeStripeStatus.pending, (state) => {
			state.loading = true;
			state.changeStripeStatusError = '';
			state.changeStripeStatusFlag = null;
		});
		builder.addCase(changeStripeStatus.fulfilled, (state, action) => {
			state.loading = false;
			state.busPartner = {
				...state.busPartner,
				stripe_payments_active: action?.meta?.arg?.stripe_payments_active
			};
			state.changeStripeStatusFlag = action?.meta?.arg?.stripe_payments_active;
		});
		builder.addCase(changeStripeStatus.rejected, (state, err) => {
			state.loading = false;
			state.changeStripeStatusError = err?.payload?.message;
			state.changeStripeStatusFlag = null;
		});

	},
});

export const { reset, logout, loggedUserInfo, setBusPartner, resetForgotPassword, resetResetPassword, resetPaymentTerms, resetConnectLink, resetAllErrors } = authSlice.actions;

export default authSlice.reducer;
