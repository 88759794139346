import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import 'dayjs/locale/it';
import { isEmpty, upperFirst } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert, Snackbar, FormControl, Select, MenuItem, Box, TextField, InputAdornment, Stack, Typography, Modal, CircularProgress, Grid, Autocomplete, Chip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AgentComponent from './AgentComponent';
import ClientComponent from './ClientComponent';
import { TagIcon, CloseIcon, CopyIcon, EmailIcon, EnglishFlag, ItalianFlag, LinkIcon, FileIcon } from '../../assets/icon';
import { sendOffer, updateRequestLanguageDetailPage, updateRequestStatus, customerNotify, getHistoryDetail, sendOfferToSupplier } from '../../redux/reqhistory';
import { updateRequestLanguage } from '../../redux/pricecalc';
import { getClientNotifyStatus } from '../../utils/helper';
import {
	HeadPart, StatusField, StatusFieldGrid, TagButton, ClientPart, BackButon, ActionButtons, SwitchLanguage, ModalContent, ModalHeader, CloseButton, ModalContentData, OnlineQuoteButton, ShareButtons,
	OfferForm
} from './styled';

const PriceQuotaComponent = ({ priceCalculationDetail, currentTab, setCurrentTab, detailPage = false }) => {
	const { userDetails, busPartner } = useSelector(state => state.auth);
	// eslint-disable-next-line no-undef
	const busPartnerLink = `${process.env.REACT_APP_BUSPARTNER_DOMAIN}${busPartner?.unique_code}`;
	const { loading, customerNotifyDetail, customerNotifyError, sendOfferDetail, sendOfferError, sendOfferToSupplierDetail, sendOfferToSupplierError } = useSelector(state => state.reqhistory);
	const status = ['created', 'declined', 'offered', 'accepted', 'confirmed', 'partpaid', 'paid'];
	const [email, setEmail] = useState(priceCalculationDetail?.requestor_email || '');
	const [error, setError] = useState(false);
	const [open, setOpen] = useState(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isShareModalOpen, setShareModalOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [receivers, setReceivers] = useState([]);
	const [newStatus, setNewStatus] = useState('');
	const { requestId } = useParams();
	const dispatch = useDispatch();
	const [alertMsg, setAlertMsg] = useState({
		msg: '',
		type: 'success'
	});
	useEffect(() => {
		if (!isEmpty(sendOfferError)) {
			setAlertMsg({
				msg: sendOfferError?.message,
				type: 'error'
			});
			setOpen(true);
		}
		if (!isEmpty(sendOfferDetail)) {
			setAlertMsg({
				msg: t('ClientNotify.NotifySuccessMsg'),
				type: 'success'
			});
			setOpen(true);
		}
	}, [sendOfferError, sendOfferDetail]);

	useEffect(() => {
		if (!isEmpty(customerNotifyError)) {
			setAlertMsg({
				msg: customerNotifyError?.message,
				type: 'error'
			});
			setOpen(true);
			setIsAddModalOpen(false);
		}
		if (!isEmpty(customerNotifyDetail)) {
			setAlertMsg({
				msg: t('ClientNotify.NotifySuccessMsg'),
				type: 'success'
			});
			setOpen(true);
			setIsAddModalOpen(false);
		}
	}, [customerNotifyDetail, customerNotifyError]);

	useEffect(() => {
		if (!isEmpty(sendOfferToSupplierDetail)) {
			setAlertMsg({
				msg: t('Offer.sendOfferSuccess'),
				type: 'success'
			});
			setOpen(true);
			setShareModalOpen(false);
			setReceivers([]);
		}
		if (!isEmpty(sendOfferToSupplierError)) {
			setAlertMsg({
				msg: t('Offer.sendOfferError'),
				type: 'error'
			});
			setOpen(true);
			setShareModalOpen(false);
			setReceivers([]);
		}
	}, [sendOfferToSupplierDetail, sendOfferToSupplierError]);
	const textToCopyRef = useRef(null);
	const copyToClipboard = (e) => {
		e.preventDefault();
		const text = textToCopyRef.current.innerText;
		const html = priceCalculationDetail?.mail_template;

		navigator.clipboard.write([
			// eslint-disable-next-line no-undef
			new ClipboardItem({
				'text/plain': new Blob([text], { type: 'text/plain' }),
				'text/html': new Blob([html], { type: 'text/html' }),
			}),
		]).then(() => {
			setAlertMsg({
				msg: t('PriceCalc.TextCopied'),
				type: 'success'
			});
		}).catch((error) => {
			setAlertMsg({
				msg: (t('PriceCalc.TextCopiedError'), error),
				type: 'error'
			});
		}).finally(() => {
			setOpen(true);
		});
	};
	const handleStatusChange = (event, record) => {
		const payload = {
			is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
			trip_request_id: record.id,
			status: event.target.value
		};
		dispatch(updateRequestStatus(payload));
		if (getClientNotifyStatus().includes(event.target.value)) {
			setIsAddModalOpen(true);
			setNewStatus(event.target.value);
		}
	};
	const handleSendOffer = () => {
		const payload = {
			is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
			trip_request_code: priceCalculationDetail.trip_code,
			requestor_email: email
		};
		dispatch(sendOffer(payload));
	};
	const handleCustomerNotify = () => {
		const payload = {
			trip_request_code: priceCalculationDetail.trip_code,
			status: newStatus,
			requestor_email: email
		};
		dispatch(customerNotify(payload));
	};
	const handleChange = (e) => {
		setEmail(e.target.value);
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i;
		if (!emailRegex.test(e.target.value)) {
			setError(true);
		} else {
			setError(false);
		}
	};
	const changeLanguage = (event, record) => {
		const payload = {
			is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
			trip_request_code: record.trip_code,
			lng: event.target.value
		};
		if (detailPage) {
			dispatch(updateRequestLanguageDetailPage(payload));
		}
		else {
			dispatch(updateRequestLanguage(payload));
		}
	};
	const handleCloseModal = () => {
		setIsAddModalOpen(false);
		const payload = { requestId, is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0 };
		dispatch(getHistoryDetail(payload));
	};
	const handleShareCloseModal = () => {
		setShareModalOpen(false);
	};
	const handleCopyButton = (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-undef
		const text = `${window.location.origin}/${busPartner?.unique_code}/offer/${priceCalculationDetail.trip_code}`;

		navigator.clipboard.write([
			// eslint-disable-next-line no-undef
			new ClipboardItem({
				'text/plain': new Blob([text], { type: 'text/plain' }),
			}),
		]).then(() => {
			setAlertMsg({
				msg: t('Offer.OfferLinkCopiedSuccess'),
				type: 'success'
			});
		}).catch(() => {
			setAlertMsg({
				msg: (t('Offer.OfferLinkCopiedError')),
				type: 'error'
			});
		}).finally(() => {
			setOpen(true);
			setShareModalOpen(false);
			setReceivers([]);
		});
	};
	const handleSendOfferToSupplier = () => {
		const payload = {
			trip_request_code: priceCalculationDetail?.trip_code,
			suppliers_email: receivers
		};
		dispatch(sendOfferToSupplier(payload));
	};
	// Function to validate email format
	const validateEmail = (email) => {
		// Basic email regex pattern for validation
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	// Handle changes when user types or adds a new email
	const handleShareEmailChange = (event, value) => {
		// Split the value on spaces and commas, trim any extra spaces, and filter out invalid emails
		const validEmails = value
			.map((v) => v.trim())
			.filter((v) => validateEmail(v));

		setReceivers(validEmails);
	};

	// Handle key press events to detect space key
	const handleKeyDown = (event) => {
		if (event.key === ' ') {
			const inputValue = event.target.value.trim();
			if (validateEmail(inputValue)) {
				setReceivers((prevReceivers) => [...prevReceivers, inputValue]);
				setInputValue(''); // Clear the input after adding a valid email
				event.target.value = ''; // Clear the input after adding a valid email
			}
			else {
				setInputValue('');
				event.target.value = ''; // Clear the input after adding a valid email
			}
			event.preventDefault(); // Prevent default space behavior
		}
	};
	return (
		<>
			{detailPage &&
				<StatusField>
					<StatusFieldGrid>
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<Select
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								className={priceCalculationDetail.status}
								value={priceCalculationDetail.status || ''}
								onChange={(e) => handleStatusChange(e, priceCalculationDetail)}
								MenuProps={{
									classes: {
										paper: 'payment_custom_status_filter_list',
									},
								}}
							>
								{status.map((name) => (
									<MenuItem key={name} value={name} className={name}>
										{t(`General.${upperFirst(name)}`)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<TagButton>
							<TagIcon /> <Box component='p'>{t(`General.${upperFirst(priceCalculationDetail.source)}`)}</Box>
						</TagButton>

						<OnlineQuoteButton href={`${busPartnerLink}/offer/${priceCalculationDetail.trip_code}`} target='_blank'>
							<Box component='p'>{t('General.OnlineOffer')}</Box>
							<FileIcon />
						</OnlineQuoteButton>

					</StatusFieldGrid>

					{/* <ShareButton>
						<Button variant='contained' onClick={() => setShareModalOpen(true)}>
							<ShareIcon />
						</Button>
					</ShareButton> */}

				</StatusField>
			}
			<HeadPart>
				{currentTab === 'client' &&
					<BackButon>
						<Button variant='contained' onClick={() => setCurrentTab('agent')} >
							<ArrowBack />
							{t('PriceCalc.Button.Back')}
						</Button>
					</BackButon>}
				{currentTab === 'client' &&
					<SwitchLanguage>
						<Select
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							value={priceCalculationDetail?.communication_language}
							onChange={(e) => changeLanguage(e, priceCalculationDetail)}
							className='flag_dropdown'
							IconComponent={() => (
								<ArrowDropDownIcon className='flagIcon' />
							)}
							MenuProps={{
								PaperProps: {
									sx: {
										top: '175px !important',
										'@media (max-width: 991px)': {
											top: '250px !important',
										}
									},
								},
							}}
						>
							<MenuItem className='flag_icon' value='IT'><Typography component='p'>IT</Typography> <ItalianFlag></ItalianFlag></MenuItem>
							<MenuItem className='flag_icon' value='EN'><Typography component='p'>EN</Typography> <EnglishFlag></EnglishFlag></MenuItem>
						</Select>
					</SwitchLanguage>
				}
				<ClientPart>
					{currentTab === 'client' &&
						<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap="wrap">
							<TextField
								placeholder={t('PriceCalc.Email')}
								error={error}
								helperText={error ? 'Invalid email format' : ''}
								type='email'
								name='requestor_email'
								onChange={handleChange}
								// onBlur={handleBlur}
								value={email}
								InputProps={{
									endAdornment: <InputAdornment position='end'><MailOutlineIcon /></InputAdornment>,
								}}
							/>
							<ActionButtons className='generalButton'>
								<Button className='copy-btn' onClick={copyToClipboard} variant='contained'><CopyIcon />{t('PriceCalc.Button.Copy')}</Button>
								<Button className='offer-btn' variant='contained' onClick={handleSendOffer} disabled={error || isEmpty(email)}>
									<MailOutlineIcon />
									{t('Agent.SendOffer')}
								</Button>
							</ActionButtons>
						</Stack>
					}
					{/* {detailPage &&
						<ButtonGroup>
							<Buttontab className={currentTab === 'agent' ? 'active' : ''} onClick={() => setCurrentTab('agent')}>
								<Button variant='contained'>{t('PriceCalc.Button.Agent')}</Button>
							</Buttontab>
							<Buttontab className={currentTab === 'client' ? 'active' : ''} onClick={() => setCurrentTab('client')}>
								<Button variant='contained'>{t('PriceCalc.Button.Client')}</Button>
							</Buttontab>
						</ButtonGroup>} */}
				</ClientPart>
			</HeadPart>
			{
				currentTab === 'client' ?
					<ClientComponent priceCalculationDetail={priceCalculationDetail} textToCopyRef={textToCopyRef} setCurrentTab={setCurrentTab} /> :
					<AgentComponent priceCalculationDetail={priceCalculationDetail} detailPage={detailPage} setCurrentTab={setCurrentTab} setShareModalOpen={setShareModalOpen} />
			}
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
				<Alert severity={alertMsg.type} sx={{ width: '100%' }}>
					{alertMsg.msg}
				</Alert>
			</Snackbar>
			{/* Start Notify Client for Status Change */}
			<Modal
				open={isAddModalOpen}
				onClose={handleCloseModal}
				aria-labelledby="client-notify"
				aria-describedby="client-notify-body">
				<ModalContent>
					<ModalHeader>
						<Typography id="client-notify" variant="h3">{t('ClientNotify.ModalTitle')}</Typography>
						<CloseButton onClick={handleCloseModal}><CloseIcon /></CloseButton>
					</ModalHeader>
					<ModalContentData id="client-notify-body">
						<React.Fragment>
							{t('ClientNotify.ModelContent').split('[Status]').map((part, index) =>
								<React.Fragment key={index}>
									{index !== 0 ? <span style={{ fontWeight: 'bold' }}>{t(`General.${upperFirst(newStatus)}`)}</span> : null}
									{part}
								</React.Fragment>
							)}
							<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" flexWrap="wrap" sx={{ marginTop: '20px', padding: '0px 15px 0px 15px' }}>
								<TextField
									sx={{ borderRadius: '6px', border: '1px' }}
									placeholder={t('PriceCalc.Email')}
									error={error}
									helperText={error ? 'Invalid email format' : ''}
									type='email'
									name='requestor_email'
									onChange={handleChange}
									value={email}
									InputProps={{
										endAdornment: <InputAdornment position='end'><MailOutlineIcon /></InputAdornment>,
									}}
								/>
								<ActionButtons className='generalButton'>
									{loading && <CircularProgress color='warning' size={24} thickness={5} sx={{ marginRight: '10px' }} />}
									<Button className='offer-btn' variant='contained' onClick={handleCustomerNotify} disabled={error || isEmpty(email) || loading}>
										{t('ClientNotify.NotifyButton')}
									</Button>
								</ActionButtons>
							</Stack>
						</React.Fragment>
					</ModalContentData>
				</ModalContent>
			</Modal>
			{/* End Notify Client for Status Change */}
			{/* Start Share Offer Model */}
			<Modal
				open={isShareModalOpen}
				onClose={handleShareCloseModal}
				aria-labelledby="share-offer"
				aria-describedby="share-offer-body">
				<ModalContent>
					<ModalHeader>
						<Typography id="share-offer" variant="h3">{t('ShareOffer.ModalTitle')}</Typography>
						<CloseButton onClick={handleShareCloseModal}><CloseIcon /></CloseButton>
					</ModalHeader>
					<ModalContentData id="share-offer-body">
						<Typography variant="subtitle1">{t('ShareOffer.ModelContent', { tripCode: priceCalculationDetail.trip_code })}</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} md={7}>
								<OfferForm>
									<Typography variant="h6">{t('ShareOffer.SuppliersEmailTitle')}</Typography>
									<Autocomplete
										multiple
										id="email-filled"
										options={[]}
										defaultValue={[]}
										freeSolo
										value={receivers}
										// eslint-disable-next-line no-unused-vars
										inputValue={inputValue} // Controlled input value
										onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Update input value on change
										onChange={handleShareEmailChange}
										onKeyDown={handleKeyDown}
										renderTags={(value, getTagProps) =>
											value.map((option, index) => {
												return (
													<Chip
														key={index}
														variant="outlined"
														label={option}
														{...getTagProps({ index })}
													/>
												);
											})
										}
										renderInput={(params) => (
											<TextField
												{...params}
												// label={t('ShareOffer.SuppliersEmail')}
												placeholder={t('ShareOffer.SuppliersEmailPlaceholder')}
											/>
										)}
									/>
								</OfferForm>
							</Grid>
							<Grid item xs={12} md={5}>
								<ShareButtons>
									<Stack spacing={1}>
										<Typography sx={{ fontWeight: 'bold' }}>{t('ShareOffer.Share')}</Typography>
										{loading && <CircularProgress color='warning' size={24} thickness={5} sx={{ marginRight: '10px' }} />}
										<Button variant='contained' onClick={handleSendOfferToSupplier} disabled={isEmpty(receivers) || loading}>
											{t('ShareOffer.ViaEmailButton')} <EmailIcon />
										</Button>
										<Typography>{t('ShareOffer.Or')}</Typography>
										<Button variant='contained' onClick={handleCopyButton}>
											{t('ShareOffer.CopyLinkButton')} <LinkIcon />
										</Button>
									</Stack>
								</ShareButtons>
							</Grid>
						</Grid>
					</ModalContentData>
				</ModalContent>
			</Modal>
			{/* End Share Offer Model */}
		</>
	);
};

export default PriceQuotaComponent;
