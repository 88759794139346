import React, { useRef } from 'react';
import SidebarComponent from '../SidebarComponent';
import { t } from 'i18next';
import { Link, Typography } from '@mui/material';

const LayoutComponent = ({ children }) => {
	const divRef = useRef(null);
	return (
		<div className="main-content">
			<div className="common-left-sidebar tet">
				<SidebarComponent referenceDiv={divRef} />
			</div>
			<div className="common-content-part test" ref={divRef}>
				{children}
			</div>

			<div className="footer-part">
				<Typography variant='span'>{t('Footer.Text1', { year: new Date().getFullYear() })} {t('Footer.Text2')}</Typography>
				<Link className='footerlink' href='/terms-conditions' color="primary" target='_blank' rel='noopener noreferrer'>{t('Footer.Page2')}</Link>
				<Link className='footerlink' href='/privacy-policy' color="primary" target='_blank' rel='noopener noreferrer'>{t('Footer.Page1')}</Link>
			</div>

		</div>
	);
};

export default LayoutComponent;