import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { HeadPart, ProfileDetalBox } from './styled';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

const ProfileDetailsComponent = () => {
	const { busPartner } = useSelector(state => state.auth);
	const busPartnerLink = `${process.env.REACT_APP_BUSPARTNER_DOMAIN}${busPartner?.unique_code}`;
	return (
		<>
			<HeadPart>
				<Typography component="h2">{t('Profile.ProfileDetail')}</Typography>
			</HeadPart>
			<ProfileDetalBox>
				<Grid container spacing={2}>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.CompanyName')}</Typography>
						<Typography variant="span">{busPartner?.name}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.Email')}</Typography>
						<Typography variant="span"><a href={`mailto:${busPartner?.email}`} target="_blank" rel="noreferrer">{busPartner?.email}</a></Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.Telephone')}</Typography>
						<Typography variant="span"><a href={`tel:${busPartner?.phone_number}`} rel="noreferrer">{busPartner?.phone_number}</a></Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.AddressCivicNo')}</Typography>
						<Typography variant="span">{busPartner?.address}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.CAP')}</Typography>
						<Typography variant="span">{busPartner?.post_code}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.City')}</Typography>
						<Typography variant="span">{busPartner?.city}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.Nation')}</Typography>
						<Typography variant="span">{busPartner?.country}</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<Typography variant="h6">{t('Profile.CustomerInterfaceLink')}</Typography>
						<Link href={busPartnerLink} className="busLink" underline="always" color="primary" target='_blank'>{busPartnerLink}</Link>
					</Grid>
				</Grid>
			</ProfileDetalBox>
		</>
	);
};

export default ProfileDetailsComponent;
