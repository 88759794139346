import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
    h2 {
        font-size: 25px;
        font-weight: 700;
        letter-spacing: -0.5px;
        color: ${theme.colors.black};
        line-height: normal;
        margin: 7px 0 12px 0;
    }
`;

export const TableContent = styled.div`
    border-radius: 21px;
    border: 1px solid ${theme.colors.grey};
    padding: 24px 30px 21px;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
        padding: 24px 15px 21px;
    }
    table {
        thead {
            th {
                color: ${theme.colors.black};
                font-size: 20px;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.4px;
                text-align: center;
                border: none;
                padding: 0 4px 12px;
            }
        }
        tbody {
            tr {
                td {
                    border: none;
                    padding: 4px 8px;
                    text-align: center;
                    svg {
                        width: 70px;
                    }
                    input {
                        padding: 10.5px 10px 7.5px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.3px;
                        color: ${theme.colors.black};
                    }
                    fieldset {
                        border: 1px solid ${theme.colors.grey};
                        border-radius: 6px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
    button.MuiButton-contained {
        border-radius: 5px;
        background: ${theme.colors.primary};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 8px 16px;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.5px;
        text-transform: none;
        margin-left: 60px;
        margin-top: 33px;
        &:before {
            content: "+";
            font-size: 30px;
            line-height: 30px;
            position: relative;
            top: 3px;
            margin-right: 5px;
        }
    }
`;

export const DeleteButton = styled.div`
    width: 24px;
    height: 24px;
    background: ${theme.colors.grey1};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
`;

// Modal CSS

export const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 528px;
    border-radius: 10px;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ModalHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 22px 7px 22px;
    border-bottom: 1px solid ${theme.colors.grey};
    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        color: ${theme.colors.black};
    }
`;
export const CloseButton = styled.div`
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.colors.grey};
    border-radius: 100px;
    background: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`;
export const ModalContentData = styled.div`
    padding: 34px 22px;
    text-align: center;
    h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        color: ${theme.colors.black};
        letter-spacing: -0.4px;
    }
`;

export const ModalButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 44px;
    button.MuiButton-contained {
        border-radius: 6px;
        background: ${theme.colors.black};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-transform: none;
        width: 95px;
        margin: 0 10px;
    }
`;

export const RedButton = styled.div`
    button.MuiButton-contained {
        background: ${theme.colors.red};
    }
`;

export const GreyButton = styled.div`
    button.MuiButton-contained {
        background: ${theme.colors.grey};
    }
`;

export const GreenButton = styled.div`
    button.MuiButton-contained {
        background: ${theme.colors.green};
    }
`;

export const AddBusField = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 21px 0;
    &:nth-last-of-type(2) {
        margin-bottom: 0;
    }
    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        color: ${theme.colors.black};
        width: 200px;
        text-align: left;
    }
    .MuiSelect-select {
        padding: 9.5px 40px 5.5px 15px;
    }
    input {
        padding: 9.5px 15px 5.5px;
        text-align: center;
    }
    svg {
        position: absolute;
        right: 10px;
        top: 14px;
    }
    fieldset {
        border-radius: 6px;
        border: 1px solid ${theme.colors.grey};
    }
`;

// End Modal CSS