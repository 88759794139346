import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './protectedRoute';
import { PublicRoute } from './publicRoute';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import ForgotPasswordPage from '../pages/auth/ForgotPassword';
import ResetPasswordPage from '../pages/auth/ResetPassword';
import ManagementPage from '../pages/management';
import ProfiloPage from '../pages/profilo';
import AdminPage from '../pages/admin';
import RequestPage from '../pages/request';
import PriceCalculationPage from '../pages/pricecalculation';
import AuthProvider from './AuthProvider';
import LayoutComponent from '../components/LayoutComponent';
import ResetPasswordSuccessPage from '../pages/auth/ResetPassword/success';
import EstimateDetailComponent from '../components/EstimateDetailComponent';
import TermsConditionPage from '../pages/terms-condition';
import PrivacyPolicyPage from '../pages/privacy-policy';
import SupplierOffer from '../pages/offers';
import OfferSuccess from '../pages/offers/success';
import SupplierOfferView from '../pages/offers/viewoffer';

const LoadPublicRouteComponent = ({ component }) => <PublicRoute>{component}</PublicRoute>;
const LoadProtectedRouteComponent = ({ component }) => <LayoutComponent><ProtectedRoute>{component}</ProtectedRoute></LayoutComponent>;

const RouterContainer = () => (
	<BrowserRouter>
		<Suspense fallback={null}>
			<AuthProvider>
				<Routes>
					<Route
						path="/login"
						element={<LoadPublicRouteComponent component={<Login />} />}
					/>
					<Route
						path="/signup"
						element={<LoadPublicRouteComponent component={<SignUp />} />}
					/>
					<Route
						path="/forgot-password"
						element={<LoadPublicRouteComponent component={<ForgotPasswordPage />} />}
					/>
					<Route
						path="/reset-password"
						element={<LoadPublicRouteComponent component={<ResetPasswordPage />} />}
					/>
					<Route
						path="/success-reset-password"
						element={<LoadPublicRouteComponent component={<ResetPasswordSuccessPage />} />}
					/>
					<Route
						path="/terms-conditions"
						element={<LoadPublicRouteComponent component={<TermsConditionPage />} />}
					/>
					<Route
						path="/privacy-policy"
						element={<LoadPublicRouteComponent component={<PrivacyPolicyPage />} />}
					/>
					<Route
						path="/:busPartnerCode/offerdetail/:tripCode/:tripIntermediationOfferId"
						element={<LoadPublicRouteComponent component={<SupplierOfferView />} />}
					/><Route
						path="/:busPartnerCode/offer/:tripCode"
						element={<LoadPublicRouteComponent component={<SupplierOffer />} />}
					/>
					<Route
						path="/:busPartnerCode/offer-submit-sucess"
						element={<LoadPublicRouteComponent component={<OfferSuccess />} />}
					/>
					<Route
						path="/management"
						element={<LoadProtectedRouteComponent component={<ManagementPage />} />}
					/>
					<Route
						path="/profile"
						element={<LoadProtectedRouteComponent component={<ProfiloPage />} />}
					/>
					<Route
						path="/"
						element={<LoadProtectedRouteComponent component={<AdminPage />} />}
					/>
					<Route
						path="/request"
						element={<LoadProtectedRouteComponent component={<RequestPage />} />}
					/>
					<Route
						path="/request/:requestId"
						element={<LoadProtectedRouteComponent component={<EstimateDetailComponent />} />}
					/>
					<Route
						path="/price-calculation"
						element={<LoadProtectedRouteComponent component={<PriceCalculationPage />} />}
					/>
				</Routes>
			</AuthProvider>
		</Suspense>
	</BrowserRouter>
);

export default RouterContainer;
