import React from 'react';
import { ClientContent } from './styled';

const ClientComponent = ({ textToCopyRef, priceCalculationDetail/* , setCurrentTab */ }) => {
	return (
		<ClientContent ref={textToCopyRef}>
			<div dangerouslySetInnerHTML={{ __html: priceCalculationDetail?.mail_template }} />
		</ClientContent>
	);
};

export default ClientComponent;
