import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const EditButton = styled.div`
    text-align: right;
    position: sticky;
    top: 0;
    background: ${theme.colors.white};
    padding: 10px 0;
    z-index: 9;
    @media screen and (max-width: 991px) {
        padding: 10px 15px;
    }
    button {
        border-radius: 26.5px;
        background: ${theme.colors.black};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        padding: 12px 22px;
        width: 140px;
        svg {
            margin-right: 5px;
            width: 22px;
            height: 22px;    
        }
        &.save-btn {
            background: ${theme.colors.green};
            svg {
                margin-right: 12px;
            }
        }
        &.cancel {
            background: ${theme.colors.grey3};
            margin: 0 0 0 10px;
        }
    }
`;

export const SuccessNotification = () => ({
});

export const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 528px;
    border-radius: 10px;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const ModalHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 22px 7px 22px;
    border-bottom: 1px solid ${theme.colors.grey};
    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        color: ${theme.colors.black};
    }
`;
export const CloseButton = styled.div`
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.colors.grey};
    border-radius: 100px;
    background: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`;
export const ModalContentData = styled.div`
    padding: 34px 22px;
    text-align: center;
    h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        color: ${theme.colors.black};
        letter-spacing: -0.4px;
    }
`;

export const ModalButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 44px;
    button.MuiButton-contained {
        border-radius: 6px;
        background: ${theme.colors.black};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-transform: none;
        width: 95px;
        margin: 0 10px;
    }
`;

export const RedButton = styled.div`
    button.MuiButton-contained {
        background: ${theme.colors.red};
    }
`;

export const GreyButton = styled.div`
    button.MuiButton-contained {
        background: ${theme.colors.grey};
    }
`;