import { createSlice } from '@reduxjs/toolkit';
import { getBusPartnerDetail, getCompany, reportFeedback } from '../company';

const initialState = {
	loading : false,
	company : [],
	companyError : '',
	busPartnerDetail : [],
	busPartnerDetailError : '',
	reportFeedbackDetail:{},
	reportFeedbackError:'',
};

const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getCompany.pending, (state) => {
			state.loading = true;
			state.company = [];
			state.companyError = '';
		});
		builder.addCase(getCompany.fulfilled, (state, action) => {
			state.loading = false;
			state.company = action.payload?.response.data.data;
		});
		builder.addCase(getCompany.rejected, (state, err) => {
			state.loading = false;
			state.companyError = err?.payload?.message;
		});

		builder.addCase(getBusPartnerDetail.pending, (state) => {
			state.loading = true;
			state.busPartnerDetail = [];
			state.busPartnerDetailError = '';
		});
		builder.addCase(getBusPartnerDetail.fulfilled, (state, action) => {
			state.loading = false;
			state.busPartnerDetail = action.payload?.response.data.data;
		});
		builder.addCase(getBusPartnerDetail.rejected, (state, err) => {
			state.loading = false;
			state.busPartnerDetailError = err?.payload?.message;
		});


		builder.addCase(reportFeedback.pending, (state) => {
			state.reportFeedbackDetail = {};
			state.reportFeedbackError = '';
		});
		builder.addCase(reportFeedback.fulfilled, (state, action) => {
			state.reportFeedbackDetail = action.payload?.response.data;
		});
		builder.addCase(reportFeedback.rejected, (state, err) => {
			state.reportFeedbackError = err?.payload?.message;
		});
	},
});

export const { reset } = companySlice.actions;

export default companySlice.reducer;
