import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI } from '../../services/auth';

export const postPriceCalculation = createAsyncThunk(
	'postPriceCalculation',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'calculation', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateManualAditional = createAsyncThunk(
	'updateManualAditional',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'update-request-quote', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const updateRequestLanguage = createAsyncThunk(
	'updateRequestLanguage',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'update-trip-request-language', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getArrivalTime = createAsyncThunk(
	'getArrivalTime',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'calculate-route', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getArrivalTimeReturn = createAsyncThunk(
	'getArrivalTimeReturn',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'calculate-route', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateIncludeVat = createAsyncThunk(
	'updateIncludeVat',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'include-vat', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);