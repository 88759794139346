import React, { useEffect, useState } from 'react';
import { Alert, Button, Divider, FormGroup, List, ListItemButton, Modal, Snackbar, TextareaAutosize, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarLogo, MainMenu, MenuIconItem, HamburgerIcon, MenuClose, MenuOverlay, /* , BackArrow */ ReportBlock, CloseButton, ModalContent, ModalContentData, ModalHeader, GreenButton, GreyButton, ButtonGroup } from './styled';
import { SettingIcon, PriceIcon, RequestIcon, ManagementIcon, Logo, ReportIcon, CloseIcon /* , BackIcon */ } from '../../assets/icon';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../utils/helper';
import { handleAdminLogOut, handleLogOut } from '../../redux/auth';
import { reportFeedback } from '../../redux/company';
import { useScreenshot } from 'use-react-screenshot';

const SidebarComponent = ({ referenceDiv }) => {
	const [, takeScreenshot] = useScreenshot();
	const getImage = () => {
		return new Promise((reslove, reject) => {
			try {
				reslove(takeScreenshot(referenceDiv.current));
			} catch (error) {
				reject(error);
			}
		});
	};
	const [textareaValue, setTextareaValue] = useState('');
	const [error, setError] = useState('');
	const { reportFeedbackDetail, reportFeedbackError } = useSelector(state => state.company);
	const handleTextareaChange = (event) => {
		setTextareaValue(event.target.value);
		if (error) setError('');
	};
	const [isActive, setIsActive] = useState(false);
	const [open, setOpen] = useState(false);
	const [alertMsg, setAlertMsg] = useState({
		msg: '',
		type: 'success'
	});
	const { t } = useTranslation();
	const location = useLocation();
	const useAuthContext = useAuth();
	const dispatch = useDispatch();
	const [reportModalShow, setReportModalShow] = useState(false);
	const { userDetails, busPartner } = useSelector(state => state.auth);
	useEffect(() => {
		if (!isEmpty(reportFeedbackError)) {
			setAlertMsg({
				msg: t('Sidebar.ReportModal.ErrorMessage'),
				type: 'error'
			});
			setOpen(true);
		}
		if (!isEmpty(reportFeedbackDetail)) {
			setAlertMsg({
				msg: t('Sidebar.ReportModal.SuccessMessage'),
				type: 'success'
			});
			setOpen(true);
		}
	}, [reportFeedbackError, reportFeedbackDetail]);
	const handleLogOutSubmit = () => {
		if (userDetails?.role == 'Admin') {
			dispatch(handleAdminLogOut({ useAuthContext }));
		}
		else {
			dispatch(handleLogOut({ useAuthContext }));
		}
	};
	const MenuOpen = () => {
		setIsActive(!isActive);
	};
	const menuClose = () => {
		setIsActive(false);
	};
	const handleCloseModal = () => {
		setTextareaValue('');
		setReportModalShow(false);
	};
	const handleReportMail = async() => {
		if (textareaValue.trim() === '') {
			setError(t('Sidebar.ReportModal.Required'));
			return;
		}
		handleCloseModal();
		await getImage().then((screenshotImage) => {
			const payload = {
				'description': textareaValue,
				'screenshot' : screenshotImage
			};
			dispatch(reportFeedback(payload));
		});
	};
	return (
		<>
			<Sidebar>
				<SidebarLogo>
					<Logo />
				</SidebarLogo>
				<HamburgerIcon onClick={MenuOpen}>
					<Divider></Divider>
					<Divider></Divider>
					<Divider></Divider>
				</HamburgerIcon>
				<MenuOverlay onClick={menuClose} className={`${isActive ? 'active' : ''}`}></MenuOverlay>
				<MainMenu className={`${isActive ? 'active' : ''}`}>
					<MenuClose onClick={menuClose}></MenuClose>
					<List>
						{!isEmpty(userDetails) && userDetails?.role == 'Admin' && <ListItemButton component={Link} to="/" selected={location.pathname === '/'}>
							<MenuIconItem>
								<SettingIcon />
							</MenuIconItem>
							<Typography variant="span" >{t('Sidebar.AdminTitle')}</Typography>
						</ListItemButton>}
						{!isEmpty(userDetails) && !(isEmpty(busPartner)) &&
							<>
								<ListItemButton onClick={menuClose} component={Link} to="/price-calculation" selected={location.pathname === '/price-calculation'}>
									<MenuIconItem>
										<PriceIcon />
									</MenuIconItem>
									<Typography variant="span" >{t('Sidebar.PriceCalculationTitle')}</Typography>
								</ListItemButton>
								<ListItemButton className='mobilehide' onClick={menuClose} component={Link} to="/request" selected={location.pathname.startsWith('/request')}>
									<MenuIconItem>
										<RequestIcon />
									</MenuIconItem>
									<Typography variant="span" >{t('Sidebar.RequestsTitle')}</Typography>
								</ListItemButton>
								<ListItemButton className='mobilehide' onClick={menuClose} component={Link} to="/management" selected={location.pathname === '/management'}>
									<MenuIconItem>
										<ManagementIcon />
									</MenuIconItem>
									<Typography variant="span" >{t('Sidebar.ManagementTitle')}</Typography>
								</ListItemButton>
								<ListItemButton onClick={menuClose} component={Link} to="/profile" selected={location.pathname === '/profile'}>
									<MenuIconItem>
										<PersonOutlineOutlinedIcon />
									</MenuIconItem>
									<Typography variant="span" >{t('Sidebar.ProfileTitle')}</Typography>
								</ListItemButton>
							</>
						}
						<ListItemButton component={Button} onClick={handleLogOutSubmit}>
							<MenuIconItem>
								<LogoutIcon />
							</MenuIconItem>
							<Typography variant="span" >{t('Sidebar.Logout')}</Typography>
						</ListItemButton>
					</List>
				</MainMenu>
				{/* <BackArrow>
					<Typography variant="h5" ></Typography>
					<BackIcon />
				</BackArrow> */}
				<ReportBlock>
					<Button variant='contained' onClick={() => setReportModalShow(true)} ><ReportIcon />{t('Sidebar.Report')}</Button>
				</ReportBlock>
				{/* Start Report Feedback Modal */}
				<Modal
					open={reportModalShow}
					onClose={handleCloseModal}
					aria-labelledby='report-feedback'
					aria-describedby='report-feedback-body'>
					<ModalContent>
						<ModalHeader>
							<Typography id='report-feedback' variant='h3'>{t('Sidebar.ReportModal.Title')}</Typography>
							<CloseButton onClick={handleCloseModal}><CloseIcon /></CloseButton>
						</ModalHeader>
						<ModalContentData id='report-feedback-body'>
							<Typography variant='h3'>
								{t('Sidebar.ReportModal.Heading')}
							</Typography>
							<Typography id='report-feedback-description' variant='span'>
								{t('Sidebar.ReportModal.Description')}
							</Typography>
							<FormGroup>
								<TextareaAutosize
									style={{ border: error ? '1px solid red' : '1px solid #89878A', borderRadius: '6px', padding: '1em', margin: '1.5em' }}
									name='problem'
									aria-label="problem"
									minRows={5}
									placeholder={t('Sidebar.ReportModal.Problem')}
									value={textareaValue}
									onChange={handleTextareaChange}
								/>
								{error && <Typography color='error'>{error}</Typography>}
							</FormGroup>
							<ButtonGroup>
								<GreyButton>
									<Button color='grey' variant='contained' onClick={handleCloseModal}>{t('Sidebar.ReportModal.CancelBtn')}</Button>
								</GreyButton>
								<GreenButton>
									<Button variant='contained' onClick={handleReportMail}>{t('Sidebar.ReportModal.SubmitBtn')}</Button>
								</GreenButton>
							</ButtonGroup>
						</ModalContentData>
					</ModalContent>
				</Modal>
				{/* End Report Feedback Modal */}
				<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
					<Alert severity={alertMsg?.type} sx={{ width: '100%' }}>
						{alertMsg?.msg}
					</Alert>
				</Snackbar>
			</Sidebar>
		</>
	);
};

export default SidebarComponent;
