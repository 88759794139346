import React, { useEffect, useState } from 'react';
import { Collapse, Stack, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { } from './styled.js';
import { StepNumber } from './styled.js';
import { StepTitle } from './styled.js';
import { Number } from './styled.js';
import { StepContent } from './styled.js';
import { AccordionButton, AccordionTitle } from './styled.js';

const StepBlock = ({ stepNo, stepTitle, isOpen = false, children }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(isOpen);
		return () => {
			setOpen(false);
		};
	}, [isOpen]);

	return (
		<StepNumber>
			<StepTitle onClick={() => setOpen((state) => !state)}>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
					<Stack direction="row" alignItems="center" flexWrap="wrap">
						<Number>
							<Typography variant="span">{stepNo}</Typography>
						</Number>
						<AccordionTitle variant="h3">{stepTitle}</AccordionTitle>
					</Stack>
					<AccordionButton>
						{open ? <ExpandLess /> : <ExpandMore />}
					</AccordionButton>
				</Stack>
			</StepTitle>
			<Collapse in={open}>
				<StepContent>
					{children}
				</StepContent>
			</Collapse>
		</StepNumber>
	);
};

export default StepBlock;
