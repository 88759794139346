import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/auth';

export const getCompany = createAsyncThunk(
	'getCompany',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'bus-partners', params, isAdmin: true });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getBusPartnerDetail = createAsyncThunk(
	'getBusPartnerDetail',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'bus-partner-details', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const reportFeedback = createAsyncThunk(
	'reportFeedback',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'report-feedback', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);