import React, { useState } from 'react';
import { t } from 'i18next';
import { isEmpty, upperFirst } from 'lodash';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, List, ListItem, Button, Container, TextField, Stack, Divider, Box, Checkbox, FormControlLabel, Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import StepBlock from '../../StepBlock';
import {
	CrossIcon, EditIcon, FlashIcon, SaveIcon, CheckedIcon,
	EmailIcon,
	ShareIcon
} from '../../../assets/icon';
import { updateManualAditionalDetailPage } from '../../../redux/reqhistory';
import { updateIncludeVat, updateManualAditional } from '../../../redux/pricecalc';
import {
	formatItalianNumber,
	getStopSequence
} from '../../../utils/helper';
import {
	EditButton, FlashIconDiv, StatisticsBlock, IncludeVat, ActionButtons, FlashContent, FlashHeading, FlashSummary, StatisticsPriceBlock,
	InviteButton, TableContent
} from './styled';
import dayjs from 'dayjs';

const AgentComponent = ({ priceCalculationDetail, detailPage, setCurrentTab, setShareModalOpen }) => {
	const { busPartner } = useSelector(state => state.auth);
	const offerlink = `${window.location.origin}/${busPartner?.unique_code}/offerdetail/${priceCalculationDetail.trip_code}`;
	const [isClassActive, setClassActive] = useState(false);
	const [includeVAT, setIncludeVAT] = useState(priceCalculationDetail?.price?.include_VAT);
	const { userDetails } = useSelector(state => state.auth);
	const [newPrice, setNewPrice] = useState(0);
	const dispatch = useDispatch();
	const handleUpdateConfig = (values) => {
		const payload = {
			trip_request_code: values.trip_code,
			new_quote_price: newPrice,
			is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
		};
		setClassActive(!isClassActive);
		if (detailPage) {
			dispatch(updateManualAditionalDetailPage(payload));
		}
		else {
			dispatch(updateManualAditional(payload));
		}
	};
	const handleEdit = (e) => {
		e.preventDefault();
		// dispatch(resetUpdateConfig());
		setClassActive(!isClassActive);
	};
	const handleIncludeVat = (e) => {
		setIncludeVAT(e.target.checked);
		dispatch(updateIncludeVat({
			'include_VAT': e.target.checked,
			'trip_request_code': priceCalculationDetail.trip_code
		}));
	};
	return (
		<>
			{!isEmpty(priceCalculationDetail) && <Formik initialValues={priceCalculationDetail} onSubmit={handleUpdateConfig}>
				{({ handleSubmit, resetForm, handleBlur }) => (
					<Form noValidate onSubmit={handleSubmit}>
						<Container maxWidth={false}>
							<IncludeVat>
								<FormControlLabel control={<Checkbox onChange={handleIncludeVat} checkedIcon={<CheckedIcon />} checked={includeVAT} />} label={t('Agent.IncludeVatPrice')} />
							</IncludeVat>
							<FlashSummary className='padding_block'>
								<FlashHeading>
									<FlashIconDiv>
										<Typography variant="span"><FlashIcon /></Typography>
									</FlashIconDiv>
									<Typography variant="h3">{t('Agent.FlashSummary')}</Typography>
								</FlashHeading>
								<FlashContent>
									<Box component="span">{t('Agent.Itinerary')}:</Box> <strong>{getStopSequence(priceCalculationDetail.customer_segments)}</strong>
									<Stack
										sx={{ textAlign: 'center' }}
										divider={<Divider orientation="vertical" flexItem />}
										direction={{ sm: 'column', md: 'row' }}
										justifyContent="center"
										alignItems="center"
									>
										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.trip_code}</Typography>
											<Typography variant='span'>{t('Agent.RequestCode')}</Typography>
										</StatisticsBlock>

										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.departure_date}</Typography>
											<Typography variant='span'>{t('Agent.DepatureDate')}</Typography>
										</StatisticsBlock>

										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.arrival_date}</Typography>
											<Typography variant='span'>{t('Agent.LastStopDate')}</Typography>
										</StatisticsBlock>

										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.passengers}</Typography>
											<Typography variant='span'>{t('Agent.Pax')}</Typography>
										</StatisticsBlock>

										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.tot_km} KM</Typography>
											<Typography variant='span'>{t('Agent.TotalKM')}</Typography>
										</StatisticsBlock>

										<StatisticsBlock>
											<Typography variant='h5'>{priceCalculationDetail.tot_empty_km} KM</Typography>
											<Typography variant='span'>{t('Agent.EmptyKM')}</Typography>
										</StatisticsBlock>

										{isClassActive ?
											<StatisticsPriceBlock>
												<Stack direction="row" justifyContent="space-between" alignItems="center">
													<Typography variant='span' sx={{ fontSize: '18px', lineHeight: '32px', marginRight: '15px' }}>
														{t('Agent.Current')}
													</Typography>
													<Typography variant='h5' className='greyPrice'>
														{includeVAT ? priceCalculationDetail.price.included_VAT_price_rounded : priceCalculationDetail.price.included_price_rounded} €
													</Typography>
												</Stack>
												<Stack direction="row" justifyContent="space-between" alignItems="center">
													<Typography variant='span' sx={{ fontSize: '18px', lineHeight: '32px', marginRight: '15px' }}>
														{t('Agent.New')}
													</Typography>
													<TextField
														size='small'
														type='number'
														onChange={(e) => setNewPrice(e.target.value)}
														onBlur={handleBlur}
														onKeyDown={(e) => {
															if (e.key === 'e' || e.key === 'E' || e.key === '+') {
																e.preventDefault();
															}
														}}
														value={newPrice}
														defaultValue={0} />
													<Typography variant='h5' sx={{ marginTop: '5px' }}> €</Typography>
												</Stack>
											</StatisticsPriceBlock>
											:
											(includeVAT ?
												<StatisticsBlock>
													<Typography variant='h5'>{priceCalculationDetail.price.included_VAT_price_rounded} €</Typography>
													<Typography variant='span'>{t('Agent.EstimatedPriceInclude')}</Typography>
												</StatisticsBlock>
												:
												<StatisticsBlock>
													<Typography variant='h5'>{priceCalculationDetail.price.included_price_rounded} €</Typography>
													<Typography variant='span'>{t('Agent.EstimatedPrice')}</Typography>
												</StatisticsBlock>)
										}
									</Stack>
								</FlashContent>
							</FlashSummary>
							<ActionButtons className='generalButton'>
								{!isClassActive ?
									<>
										<Button className='share-btn' variant='contained' onClick={() => setShareModalOpen(true)}>
											{t('Agent.ResellTravel')} <ShareIcon />
										</Button>
										<Button className='modify-price-btn' variant='contained' onClick={handleEdit}>
											{t('Agent.ModifyPrice')} <EditIcon />
										</Button>
										<Button className='offer-btn' variant='contained' onClick={() => setCurrentTab('client')}>
											{t('Agent.SendOffer')} <MailOutlineIcon />
										</Button>
									</>
									:
									<EditButton className='stepOneButton'><div>
										<Button variant='contained' type='submit' className='save-btn'><SaveIcon />{t('Management.Save')}</Button>
										<Button variant='contained' onClick={() => {
											resetForm();
											setClassActive(!isClassActive);
										}} className='cancel'><CrossIcon />{t('Management.Cancel')}</Button>
									</div>
									</EditButton>}
							</ActionButtons>
							<StepBlock stepNo={1} stepTitle={t('Agent.PriceSummary')}>
								<TableContainer component={Paper} sx={{ marginTop: '30px', maxWidth: '450px', boxShadow: 'none', borderRadius: 0, }}>
									<Table className='tripTable'>
										<TableHead>
											<TableRow>
												<TableCell align="left">{t('Agent.Typology')}</TableCell>
												<TableCell align="center">{t('Agent.TotalValue')}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell align="left">{t('Agent.BusCost')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.bus_base_price} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.HighSeasonIncrease')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.high_season_addition} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.WeekendIncrease')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.weekend_addition} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.ExtraKM')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.extra_km_price} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.SecondDriver')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.second_driver_price} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.NightDriving')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.night_driving_price} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.DriverTransport')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.driver_change_cost} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.ManualAddition')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.manual_addition} €</TableCell>
											</TableRow>
											{includeVAT ? (
												<>
													{/* Rows to show when includeVAT is true */}
													<TableRow>
														<TableCell align="left">{t('Agent.Rounding')}</TableCell>
														<TableCell align="center">{priceCalculationDetail.price.rounding_component_with_VAT} €</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className='grey' align="left">{t('Agent.NetTotalExclVat')}</TableCell>
														<TableCell className='grey' align="center">{(priceCalculationDetail.price.included_price + priceCalculationDetail.price.rounding_component_with_VAT).toFixed(1)} €</TableCell>
													</TableRow>
													{Object.entries(priceCalculationDetail.price.VAT_components).map(([vatKey, vatValue], vatIndex) => {
														return (
															<TableRow key={vatIndex}>
																<TableCell align="left">{t('Agent.VAT')} {vatKey}</TableCell>
																<TableCell align="center">{vatValue} €</TableCell>
															</TableRow>
														);
													})}
													{/* Add more rows as needed for includeVAT case */}
												</>
											) : (
												<>
													{/* Rows to show when includeVAT is false */}
													<TableRow>
														<TableCell align="left">{t('Agent.Rounding')}</TableCell>
														<TableCell align="center">{priceCalculationDetail.price.rounding_component} €</TableCell>
													</TableRow>
													{/* Add more rows as needed for non-includeVAT case */}
												</>
											)}
										</TableBody>
										<TableFooter>
											{includeVAT ? (
												<>
													{/* Rows to show when includeVAT is true */}
													<TableRow>
														<TableCell align="left">{t('Agent.GrossTotalVat')}</TableCell>
														<TableCell align="center">{priceCalculationDetail.price.included_VAT_price_rounded} €</TableCell>
													</TableRow>
													{/* Add more rows as needed for includeVAT case */}
												</>
											) : (
												<>
													{/* Rows to show when includeVAT is false */}
													<TableRow>
														<TableCell className='grey' align="left">{t('Agent.NetTotalExclVat')}</TableCell>
														<TableCell className='grey' align="center">{priceCalculationDetail.price.included_price_rounded} €</TableCell>
													</TableRow>
													{/* Add more rows as needed for non-includeVAT case */}
												</>
											)}
										</TableFooter>
									</Table>
								</TableContainer>
								<Typography sx={{ fontSize: '22px', fontWeight: '700', marginTop: '30px' }} variant="h4">Costi extra (non inclusi nel prezzo)</Typography>
								<TableContainer component={Paper} sx={{ maxWidth: '300px', boxShadow: 'none', borderRadius: 0, }}>
									<Table className='tripTable'>
										<TableHead>
											<TableRow>
												<TableCell align="left">{t('Agent.Typology')}</TableCell>
												<TableCell align="center">{t('Agent.TotalValue')} €</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell align="left">{t('Agent.Accommodation')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.overnight_stay_cost} €</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="left">{t('Agent.Food')}</TableCell>
												<TableCell align="center">{priceCalculationDetail.price.meals_cost} €</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</StepBlock>

							<StepBlock stepNo={2} stepTitle={t('Agent.TripSummary')} >
								<List>
									<ListItem>{t('Agent.Itinerary')}: <strong>{getStopSequence(priceCalculationDetail.customer_segments)}</strong></ListItem>
									<ListItem>{t('Agent.NoPass')}: <strong>{priceCalculationDetail.passengers}</strong></ListItem>
									{/* <ListItem>{t('Agent.NoBus')}: <strong>2(STATIC)</strong></ListItem> */}
									<ListItem>{t('Agent.BusOnSite')}: <strong>{priceCalculationDetail.bus_on_site_flag ? t('General.Yes') : t('General.No')}</strong></ListItem>
									<ListItem>{t('Agent.RoundTrip')}: <strong>{priceCalculationDetail.return_trip_flag ? t('General.Yes') : t('General.No')}</strong></ListItem>
									{
										priceCalculationDetail.requestor_email !== null &&
										<>
											<ListItem>{t('Agent.Email')}: <strong>{priceCalculationDetail.requestor_email}</strong></ListItem>
										</>
									}
									{
										priceCalculationDetail.client_type !== null &&
										priceCalculationDetail.source === 'online' &&
										<>
											<ListItem>{t('Agent.ClientType')}: <strong>{t(`ClientTypes.${upperFirst(priceCalculationDetail.client_type)}`)}</strong></ListItem>
											<ListItem>{t('Agent.AdditionInfo')}: <strong>{priceCalculationDetail.additional_information}</strong></ListItem>
										</>
									}
									{
										priceCalculationDetail.client_type === 'Private' &&
										priceCalculationDetail.source === 'online' &&
										priceCalculationDetail.client_name !== null &&
										<>
											<ListItem>{t('Agent.ClientName')}: <strong>{priceCalculationDetail.client_name}</strong></ListItem>
											<ListItem>{t('Agent.ClientSurname')}: <strong>{priceCalculationDetail.client_surname}</strong></ListItem>
											<ListItem>{t('Agent.ClientTelephone')}: <strong>{priceCalculationDetail.client_number}</strong></ListItem>
											<ListItem>{t('Agent.EventType')}: {priceCalculationDetail.event_type && <strong>{t(`EventTypes.${upperFirst(priceCalculationDetail.event_type)}`)}</strong>}</ListItem>
										</>
									}
									{
										priceCalculationDetail.client_type === 'Company' &&
										priceCalculationDetail.source === 'online' &&
										priceCalculationDetail.client_name !== null &&
										<>
											<ListItem>{t('Agent.ClientName')}: <strong>{priceCalculationDetail.client_name}</strong></ListItem>
											<ListItem>{t('Agent.ClientSurname')}: <strong>{priceCalculationDetail.client_surname}</strong></ListItem>
											<ListItem>{t('Agent.CompanyName')}: <strong>{priceCalculationDetail.company_name}</strong></ListItem>
											<ListItem>{t('Agent.CompanyVATNumber')}: <strong>{priceCalculationDetail.company_VAT_number}</strong></ListItem>
											<ListItem>{t('Agent.CompanyTelephone')}: <strong>{priceCalculationDetail.company_number}</strong></ListItem>
											<ListItem>{t('Agent.CompanyAddress')}: <strong>{priceCalculationDetail.company_address}</strong></ListItem>
											<ListItem>{t('Agent.EventType')}: {priceCalculationDetail.event_type && <strong>{t(`EventTypes.${upperFirst(priceCalculationDetail.event_type)}`)}</strong>}</ListItem>
										</>
									}
								</List>
								<Typography sx={{ fontSize: '22px', fontWeight: '700', marginTop: '30px' }} variant="h4">{t('Agent.Table')} <span>{t('Agent.CustTrip')}</span></Typography>
								<TableContainer component={Paper} sx={{ maxWidth: '950px', boxShadow: 'none', borderRadius: 0, }}>
									<Table className='tripTable'>
										<TableHead>
											<TableRow>
												<TableCell align="center">{t('Agent.Stop')}</TableCell>
												<TableCell align="center">{t('Agent.From')}</TableCell>
												<TableCell align="center">{t('Agent.To')}</TableCell>
												<TableCell align="center">{t('Agent.DepatureDateTime')}</TableCell>
												<TableCell align="center">{t('Agent.ArrivalDateTime')}*</TableCell>
												<TableCell align="center">{t('Agent.KM')}</TableCell>
												<TableCell align="center">{t('Agent.DrivingTime')}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{priceCalculationDetail?.customer_segments.map((seg, index) => {
												return (
													<TableRow key={index}>
														<TableCell align="center">{index + 1}</TableCell>
														<TableCell align="center"><span>{seg.departure_stop.address}</span></TableCell>
														<TableCell align="center"><span>{seg.destination_stop.address}</span></TableCell>
														<TableCell align="center"><strong>{seg.departure_stop.departure_datetime}</strong></TableCell>
														<TableCell align="center"><strong>{seg.destination_stop.arrival_datetime}</strong></TableCell>
														<TableCell align="center"><strong>{seg.tot_KM}</strong></TableCell>
														<TableCell align="center"><strong>{seg.driving_time}</strong></TableCell>
													</TableRow>);
											})}
										</TableBody>
									</Table>
								</TableContainer>
								<Typography sx={{ fontSize: '13px', fontWeight: '400', marginTop: '10px' }} variant="h4">*{t('Agent.DriverBreakCondition')}</Typography>
							</StepBlock>

							<StepBlock stepNo={3} stepTitle={t('Agent.TravelExecution')} >
								<List>
									<ListItem>{t('Agent.ExecutionMethods')}: <strong>{priceCalculationDetail.execution_type}</strong></ListItem>
									<ListItem>{t('Agent.NoRoutes')}: <strong>{priceCalculationDetail.price_details.length}</strong></ListItem>
								</List>
								{priceCalculationDetail.price_details.map((pd, index) => {
									return (
										<React.Fragment key={index}>
											<Typography variant="button">{t('Agent.DetailRoute')} {index + 1}</Typography>
											<Typography sx={{ fontSize: '22px', fontWeight: '700', marginTop: '10px' }} variant="h4">{t('Agent.RouteExecution')} {index + 1}</Typography>
											<TableContainer component={Paper} sx={{ maxWidth: '950px', boxShadow: 'none', borderRadius: 0, }}>
												<Table className='tripTable'>
													<TableHead>
														<TableRow>
															<TableCell align="center">{t('Agent.Stop')}</TableCell>
															<TableCell align="center">{t('Agent.From')}</TableCell>
															<TableCell align="center">{t('Agent.To')}</TableCell>
															<TableCell align="center">{t('Agent.DepatureDateTime')}</TableCell>
															<TableCell align="center">{t('Agent.ArrivalDateTime')}*</TableCell>
															<TableCell align="center">{t('Agent.KM')}</TableCell>
															<TableCell align="center">{t('Agent.DrivingTime')}</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{pd?.bus_segments.map((seg, bsIndex) => {
															return (
																<TableRow key={bsIndex}>
																	<TableCell align="center">{bsIndex + 1}</TableCell>
																	<TableCell align="center"><span>{seg.departure_stop.address}</span></TableCell>
																	<TableCell align="center"><span>{seg.destination_stop.address}</span></TableCell>
																	<TableCell align="center"><strong>{seg.departure_stop.departure_datetime}</strong></TableCell>
																	<TableCell align="center"><strong>{seg.destination_stop.arrival_datetime}</strong></TableCell>
																	<TableCell align="center"><strong>{seg.tot_KM}</strong></TableCell>
																	<TableCell align="center"><strong>{seg.driving_time}</strong></TableCell>
																</TableRow>);
														})}
													</TableBody>
												</Table>
											</TableContainer>
											<Typography sx={{ fontSize: '13px', fontWeight: '400', marginTop: '10px' }} variant="h4">*{t('Agent.DriverBreakCondition')}</Typography>
											{pd?.buses_price.map((busPrice, busPriceIndex) => {
												return (
													<React.Fragment key={busPriceIndex}>
														<Typography sx={{ fontSize: '22px', fontWeight: '700', marginTop: '30px' }} variant="h4">
															{t('Agent.CoachPrice')} {busPrice.num_seats} {t('Agent.Passengers')}
														</Typography>
														<TableContainer component={Paper} sx={{ marginTop: '20px', maxWidth: '750px', boxShadow: 'none', borderRadius: 0, }}>
															<Table className='tripTable'>
																<TableHead>
																	<TableRow>
																		<TableCell align="left">{t('Agent.Typology')}</TableCell>
																		<TableCell align="center">{t('Agent.Amount')}</TableCell>
																		<TableCell align="center">{t('Agent.UnitRate')} €</TableCell>
																		<TableCell align="center">{t('Agent.TotalValue')} €</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow>
																		<TableCell align="left">{t('Agent.BusCost')}</TableCell>
																		<TableCell align="center"><span>{busPrice.share_of_DR} {t('Agent.Days')}</span></TableCell>
																		<TableCell align="center"><span>{busPrice.daily_rate} €/{t('Agent.Day')}</span></TableCell>
																		<TableCell align="center">{busPrice.bus_base_price} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.HighSeasonIncrease')}</TableCell>
																		<TableCell align="center"><span>{busPrice.bus_base_price} €</span></TableCell>
																		<TableCell align="center"><span>{busPrice.markup_high_season * 100} %</span></TableCell>
																		<TableCell align="center">{busPrice.high_season_addition} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.WeekendIncrease')}</TableCell>
																		<TableCell align="center"><span>{busPrice.bus_base_price} €</span></TableCell>
																		<TableCell align="center"><span>{busPrice.markup_weekend * 100} %</span></TableCell>
																		<TableCell align="center">{busPrice.weekend_addition} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.ExtraKM')}</TableCell>
																		<TableCell align="center"><span>{busPrice.extra_KM_quantity} {t('Agent.KM')}</span></TableCell>
																		<TableCell align="center"><span>{busPrice.unitary_cost_extra_KM} €/{t('Agent.KM')}</span></TableCell>
																		<TableCell align="center">{busPrice.extra_KM_cost} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.SecondDriver')}</TableCell>
																		<TableCell align="center"><span>{busPrice.second_driver_share_DR} {t('Agent.Days')}</span></TableCell>
																		<TableCell align="center"><span>{busPrice.second_driver_daily_cost} €/{t('Agent.Day')}</span></TableCell>
																		<TableCell align="center">{busPrice.second_driver_cost} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.NightDriving')}</TableCell>
																		<TableCell align="center"><span>{busPrice.tot_night_driving_hours} {t('Agent.Hours')}</span></TableCell>
																		<TableCell align="center"><span>{busPrice.night_driving_hourly_cost} €/{t('Agent.Hour')}</span></TableCell>
																		<TableCell align="center">{busPrice.night_drive_cost} €</TableCell>
																	</TableRow>
																</TableBody>
																<TableHead>
																	<TableRow>
																		<TableCell align="left">{t('Agent.TotalBus')}</TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="center">{busPrice.included_price} €</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	<TableRow>
																		<TableCell align="left">{t('Agent.Accommodation')}</TableCell>
																		<TableCell align="center"><span>{busPrice.num_overnight_stay} {t('Agent.Nights')}</span><br /><span>({t('Agent.TotalDrivers')})</span></TableCell>
																		<TableCell align="center"><span>{busPrice.base_overnight_stay_cost} €/{t('Agent.Night')}</span></TableCell>
																		<TableCell align="center">{busPrice.overnight_stay_cost} €</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell align="left">{t('Agent.Food')}</TableCell>
																		<TableCell align="center"><span>{busPrice.num_meals} {t('Agent.Meals')}</span></TableCell>
																		<TableCell align="center"><span>{busPrice.base_meals_cost} €/{t('Agent.Meal')}</span></TableCell>
																		<TableCell align="center">{busPrice.meals_cost} €</TableCell>
																	</TableRow>
																</TableBody>
																<TableHead>
																	<TableRow>
																		<TableCell align="left">{t('Agent.TotalExtra')}</TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="center">{busPrice.meals_cost + busPrice.overnight_stay_cost} €</TableCell>
																	</TableRow>
																</TableHead>
																<TableFooter>
																	<TableRow>
																		<TableCell align="left">{t('Agent.TotalGrand')}</TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="left"></TableCell>
																		<TableCell align="center">{busPrice.tot_price} €</TableCell>
																	</TableRow>
																</TableFooter>
															</Table>
														</TableContainer>
													</React.Fragment>
												);
											})}

										</React.Fragment>
									);
								})}
							</StepBlock>

							<StepBlock stepNo={4} stepTitle={t('Agent.SupplierSummary')} >
								<Typography sx={{ fontSize: '22px', fontWeight: '700', marginTop: '30px' }} variant="h4">
									{t('Agent.SupplierTable')}
								</Typography>
								<TableContent>
									<TableContainer>
										<Table className='simpleTable' aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell>{t('Agent.SupplierName')}</TableCell>
													<TableCell>{t('Agent.SupplierPrice')}</TableCell>
													<TableCell>{t('Agent.SupplierOfferDate')}</TableCell>
													<TableCell>{t('Agent.SupplierTelephone')}</TableCell>
													<TableCell>{t('Agent.SupplierActions')}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{!isEmpty(priceCalculationDetail?.supplier_summary) ? priceCalculationDetail.supplier_summary.map((supplier, index) => {
													return (
														<TableRow key={index}>
															<TableCell>
																<Link className='steplink' target='_blank' rel='noopener noreferrer' href={`${offerlink}/${supplier.id}`}>
																	{supplier.supplier_name}
																</Link>
															</TableCell>
															<TableCell>{`${formatItalianNumber(supplier.offer_amount)} €`}</TableCell>
															<TableCell>{dayjs(supplier.creation_date).format('DD/MM/YYYY HH:mm')}</TableCell>
															<TableCell>{supplier.supplier_telephone_number}</TableCell>
															<TableCell>
																<InviteButton>
																	<Button variant='contained' href={`mailto:${supplier.supplier_email}`}>
																		{t('Agent.SendEmail')} <EmailIcon />
																	</Button>
																</InviteButton>
															</TableCell>
														</TableRow>
													);
												}) :
													<TableRow>
														<TableCell colSpan={5}>{t('Agent.NoSupplierFound')}</TableCell>
													</TableRow>}
											</TableBody>
										</Table>
									</TableContainer>
								</TableContent >
							</StepBlock>
						</Container>
					</Form >
				)}
			</Formik >}
		</>
	);
};

export default AgentComponent;
