import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import { Title, UserForm, MainBox, CheckMark, HeadingWithIcon } from './styled.js';
import HeaderComponent from '../../../components/HeaderComponent';
import { CheckmarkIcon } from '../../../assets/icon/index.js';

const ResetPasswordSuccessPage = () => {
	const navigate = useNavigate();
	return (
		<>
			<HeaderComponent />
			<Container>
				<MainBox>
					<Title>
						<HeadingWithIcon>
							<CheckMark>
								<CheckmarkIcon />
							</CheckMark>
							<Typography variant="h1" component="h1">{t('ResetPasswordSuccess.Title')}</Typography>
						</HeadingWithIcon>
						<Typography variant="h1" component="p">{t('ResetPasswordSuccess.Info')}</Typography>
					</Title>
					<UserForm>
						<Button
							onClick={() => navigate('/login')}
							variant="contained">
							{t('ResetPasswordSuccess.LoginButton')}
						</Button>
					</UserForm>
				</MainBox>
			</Container >
		</>
	);
};

export default ResetPasswordSuccessPage;
