import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { usePlacesWidget } from 'react-google-autocomplete';
import { InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { RightIcon, PinIcon, DeleteIcon } from '../../assets/icon';
import { getArrivalTime } from '../../redux/pricecalc';
import { TripBox, BoxHeading, Number, TripFeild, LeftPart, RightArrow, InputBox, RightPart, DateSelect, TimeSelect, DeleteButton } from './styled';
import { addDurationToTime, convertTripDetails, extractDetails, roundToNearest15MinutesAfterAddingDuration } from '../../utils/helper';

dayjs.extend(updateLocale);
dayjs.updateLocale('it', {
	weekStart: 1
});
const TripBoxComponent = ({ index, deleteStop }) => {
	const dispatch = useDispatch();
	const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();
	const [startPointAddress, setStartPointAddress] = useState({});
	const [endPointAddress, setEndPointAddress] = useState({});
	const [depatureDateTime, ] = useState(dayjs(values.trip_stops[index].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[index].time).format('HH:mm'));
	const [duration, setDuration] = useState(values.trip_stops[index]['duration']);
	const { arrivalTimeDetail } = useSelector(state => state.pricecalc);
	const { ref: materialRef } = usePlacesWidget({
		async: true,
		// eslint-disable-next-line no-undef
		apiKey: process.env.REACT_APP_GOOGLE,
		onPlaceSelected: (place) => {
			const googleAddress = extractDetails(place.address_components);
			const address = {
				lat: place.geometry.location.lat(),
				lon: place.geometry.location.lng(),
				address: place.formatted_address,
				...googleAddress
			};
			setStartPointAddress(address);
			setFieldValue(`trip_stops[${index}]['start']`, address, false);
		},
		inputAutocompleteValue: 'formatted_address',
		options: {
			types: ['geocode', 'establishment']//'street_number', 'street_address', 'locality', 'point_of_interest']
			//locationbias: 'ipbias'
			//componentRestrictions: { country: getEuropeCountries() },
		},
	});
	const { ref: materialRef2 } = usePlacesWidget({
		async: true,
		// eslint-disable-next-line no-undef
		apiKey: process.env.REACT_APP_GOOGLE,
		onPlaceSelected: (place) => {
			const googleAddress = extractDetails(place.address_components);
			const address = {
				lat: place.geometry.location.lat(),
				lon: place.geometry.location.lng(),
				address: place.formatted_address,
				...googleAddress
			};
			setEndPointAddress(address);
			setFieldValue(`trip_stops[${index}]['end']`, address, false);
		},
		inputAutocompleteValue: 'formatted_address',
		options: {
			types: ['geocode', 'establishment']//'street_number', 'street_address', 'locality', 'point_of_interest']
			//locationbias: 'ipbias'
			//componentRestrictions: { country: getEuropeCountries() },
		},
	});

	useEffect(() => {
		if (values.trip_stops && values.trip_stops[index + 1] && !isEmpty(endPointAddress)) {
			setFieldValue(`trip_stops[${index + 1}].start`, { ...endPointAddress });
		}
		else if (values.return_flag && index === (values.trip_stops.length - 1) && !isEmpty(endPointAddress)) {
			setFieldValue('return_stop.start', { ...endPointAddress });
		}
		else if (!values.return_flag) {
			setFieldValue('return_stop', {});
		}
	}, [endPointAddress, values.return_flag]);

	useEffect(() => {
		if (values.return_flag && index === 0 && !isEmpty(startPointAddress)) {
			setFieldValue('return_stop.end', { ...startPointAddress });
		}
		else if (!values.return_flag) {
			setFieldValue('return_stop', {});
		}
	}, [startPointAddress]);
	const handleGlobalChange = () => {
		const tripStops = convertTripDetails(values.trip_stops[index]);
		const payload = {
			'transport_mode': 'truck',
			'routing_mode': 'fast',
			'index': index,
			...tripStops
		};
		if (!isEmpty(tripStops)) {
			dispatch(getArrivalTime(payload));
		}
	};
	useEffect(() => {
		if (!isEmpty(arrivalTimeDetail) && arrivalTimeDetail.index == index) {
			setDuration(arrivalTimeDetail.duration);
		}
	}, [arrivalTimeDetail]);

	useEffect(() => {
		if (arrivalTimeDetail.index == index) {
			setFieldValue(`trip_stops[${arrivalTimeDetail.index}].duration`, arrivalTimeDetail.duration);
			setFieldValue(`trip_stops[${arrivalTimeDetail.index}].arrival`, addDurationToTime(dayjs(values.trip_stops[index].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[index].time).format('HH:mm'), arrivalTimeDetail.duration));
			setFieldValue(`trip_stops[${arrivalTimeDetail.index}].next_depature`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[index].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[index].time).format('HH:mm'), arrivalTimeDetail.duration));
		}
		else if (arrivalTimeDetail.index != undefined) {
			setFieldValue(`trip_stops[${index}].duration`, duration);
			setFieldValue(`trip_stops[${index}].arrival`, addDurationToTime(depatureDateTime, duration));
			setFieldValue(`trip_stops[${index}].next_depature`, roundToNearest15MinutesAfterAddingDuration(depatureDateTime, duration));
		}
	}, [duration, depatureDateTime]);

	useEffect(() => {
		// This effect will run whenever the specific field is updated
		handleGlobalChange();
	}, [values.trip_stops[index]['start'], values.trip_stops[index]['end']]);

	/* useEffect(() => {
		if (values.trip_stops[index]?.duration > 0) {
			console.log(values.trip_stops[index]);
			// setFieldValue(`trip_stops[${index}].arrival`, dayjs(depatureDateTime).add(values.trip_stops[index].duration, 'seconds'));
		}
	}, [values.trip_stops[index].time, values.trip_stops[index].date]); */

	const updateTripTime = (currentTimestamp, field) => {
		let trips = values.trip_stops;
		for (let tindex = index; tindex < trips.length; tindex++) {
			let currentStop = trips[tindex];
			// console.log('currentStop Date TIME : ', dayjs(currentStop.date).format('YYYY-MM-DD') + ' ' + dayjs(currentStop.time).format('HH:mm'));
			let currentdatetime = field == 'date' ?
				currentTimestamp.format('YYYY-MM-DD') + ' ' + dayjs(currentStop.time).format('HH:mm')
				:
				dayjs(currentStop.date).format('YYYY-MM-DD') + ' ' + currentTimestamp.format('HH:mm');
			// let currentdatetime = dayjs(currentStop.date).format('YYYY-MM-DD') + ' ' + currentTimestamp.format('HH:mm');
			if ((currentStop['duration'] ?? 0)) {
				// console.log('Before', currentStop);
				/* if (tindex > 0) {
					console.log(tindex, dayjs(trips[tindex - 1]['next_depature']).isAfter(dayjs(dayjs(trips[tindex].date).format('YYYY-MM-DD') + ' ' + dayjs(trips[tindex].time).format('HH:mm')), 'minute'));
				} */
				if (tindex > 0 && tindex > index && dayjs(trips[tindex - 1]['next_depature']).isAfter(dayjs(dayjs(trips[tindex].date).format('YYYY-MM-DD') + ' ' + dayjs(trips[tindex].time).format('HH:mm')), 'minute')) {
					/* console.log(dayjs(dayjs(trips[tindex].date).format('YYYY-MM-DD') + ' ' + dayjs(trips[tindex].time).format('HH:mm')).format('YYYY-MM-DD HH:mm'));
					console.log(dayjs(trips[tindex - 1]['next_depature']).format('YYYY-MM-DD HH:mm'));
					console.log('==================================='); */
					currentdatetime = dayjs(trips[tindex - 1]['next_depature']).format('YYYY-MM-DD HH:mm');
					currentStop['date'] = dayjs(trips[tindex - 1]['next_depature']);
					currentStop['time'] = dayjs(trips[tindex - 1]['next_depature']);
					currentStop['arrival'] = addDurationToTime(currentdatetime, currentStop['duration']);
					currentStop['next_depature'] = roundToNearest15MinutesAfterAddingDuration(currentdatetime, currentStop['duration']);
				}
				if (tindex > index) {
					currentdatetime = dayjs(trips[tindex - 1]['next_depature']).format('YYYY-MM-DD HH:mm');
				}
				if (tindex == index) {
					currentStop['date'] = currentdatetime;
					currentStop['time'] = currentdatetime;
					currentStop['arrival'] = addDurationToTime(currentdatetime, currentStop['duration']);
					currentStop['next_depature'] = roundToNearest15MinutesAfterAddingDuration(currentdatetime, currentStop['duration']);
				}
				// console.log('After', currentStop);
				trips[tindex] = currentStop;
			}
		}
		setFieldValue('trip_stops', trips);
		if (values.return_flag && values.return_stop.duration && dayjs(trips[trips.length - 1]['next_depature']).isAfter(dayjs(dayjs(values.return_stop.date).format('YYYY-MM-DD') + ' ' + dayjs(values.return_stop.time).format('HH:mm')), 'minute')) {
			let returnTrip = values.return_stop;
			let currentdatetime = dayjs(dayjs(returnTrip.date).format('YYYY-MM-DD') + ' ' + dayjs(returnTrip.time).format('HH:mm')).format('YYYY-MM-DD HH:mm');
			returnTrip['date'] = dayjs(trips[trips.length - 1]['next_depature']);
			returnTrip['time'] = dayjs(trips[trips.length - 1]['next_depature']);
			returnTrip['arrival'] = addDurationToTime(currentdatetime, returnTrip['duration']);
			returnTrip['next_depature'] = roundToNearest15MinutesAfterAddingDuration(currentdatetime, returnTrip['duration']);
			setFieldValue('return_stop', returnTrip);
		}
	};
	return (
		<TripBox>
			<BoxHeading>
				<Number>
					<Typography variant='span'>{index + 1}</Typography>
				</Number>
				<Typography variant='h3'>{index > 0 ? t('PriceCalc.Stop') : t('PriceCalc.Gone')}</Typography>
				<input type='hidden' name={`trip_stops[${index}][sequence]`} value={index + 1}></input>
				{index > 0 ?
					<DeleteButton onClick={deleteStop}>
						<DeleteIcon />
					</DeleteButton> : ''}
			</BoxHeading>
			<TripFeild>
				<LeftPart>
					<InputBox>
						<InputLabel htmlFor={`trip_stops[${index}][start][address]`}>{t('PriceCalc.StartPoint')}</InputLabel>
						<TextField
							disabled={index > 0}
							id={`trip_stops[${index}][start][address]`}
							name={`trip_stops[${index}][start][address]`}
							error={Boolean(touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].start && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].start && errors.trip_stops[index].start.lat && errors.trip_stops[index].start.lon)}
							helperText={touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].start && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].start && errors.trip_stops[index].start.lat && errors.trip_stops[index].start.lon}
							value={values.trip_stops[index]['start']['address']}
							inputRef={materialRef}
							onChange={(e) => {
								const newValue = e.target.value;
								setFieldValue(`trip_stops[${index}]['start']`, null);
								setFieldValue(`trip_stops[${index}]['start']['address']`, newValue);
							}}
							onBlur={handleBlur}
							InputProps={{
								startAdornment: <InputAdornment position='start'><PinIcon /></InputAdornment>,
							}}
							sx={{
								'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
									border: '2px solid #FF0000',
								},
							}} />
					</InputBox>
					<RightArrow>
						<RightIcon />
					</RightArrow>
					<InputBox>
						<InputLabel htmlFor={`trip_stops[${index}][end][address]`}>{t('PriceCalc.Destination')}</InputLabel>
						<TextField
							id={`trip_stops[${index}][end][address]`}
							name={`trip_stops[${index}][end][address]`}
							error={Boolean(touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].end && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].end && errors.trip_stops[index].end.lat && errors.trip_stops[index].end.lon)}
							helperText={touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].end && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].end && errors.trip_stops[index].end.lat && errors.trip_stops[index].end.lon}
							value={values.trip_stops[index]['end']['address']}
							inputRef={materialRef2}
							onChange={(e) => {
								const newValue = e.target.value;
								setFieldValue(`trip_stops[${index}]['end']`, null);
								setFieldValue(`trip_stops[${index}]['end']['address']`, newValue);
							}}
							onBlur={handleBlur}
							InputProps={{
								startAdornment: <InputAdornment position='start'><PinIcon /></InputAdornment>,
							}}
							sx={{
								'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
									border: '2px solid #FF0000',
								},
							}} />
					</InputBox>
				</LeftPart>
				<RightPart>
					<DateSelect>
						<InputLabel htmlFor={`trip_stops[${index}][date]`}>{t('PriceCalc.DepatureDate')}</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='it'>
							<DatePicker
								disablePast
								id={`trip_stops[${index}][date]`}
								name={`trip_stops[${index}][date]`}
								error={Boolean(touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].date && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].date)}
								slotProps={{
									textField: {
										required: true,
										helperText: touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].date && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].date
									},
								}}
								minDate={index > 0 ? dayjs(values.trip_stops[index - 1]['next_depature']) : dayjs()}
								format='DD/MM/YYYY'
								onChange={(value) => {
									updateTripTime(dayjs(value), 'date');
									setFieldValue(`trip_stops[${index}][date]`, dayjs(value), false);
									/* setDepatureDateTime(dayjs(value).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[index].time).format('HH:mm'));
									if ((index + 1) < values.trip_stops.length) {
										for (let i = index + 1; i < values.trip_stops.length; i++) {
											setFieldValue(`trip_stops[${i}].duration`, values.trip_stops[i]['duration']);

											setFieldValue(`trip_stops[${i}].arrival`, addDurationToTime(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), values.trip_stops[i]['duration']));

											setFieldValue(`trip_stops[${i}].next_depature`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), values.trip_stops[i]['duration']));

											setFieldValue(`trip_stops[${i}].date`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), duration));


											setFieldValue(`trip_stops[${i}].time`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), duration));
										}
									} */
									// handleGlobalChange();
								}}
								onBlur={handleBlur}
								value={dayjs(values.trip_stops[index]['date'])}
							/>
						</LocalizationProvider>
					</DateSelect>
					<TimeSelect>
						<InputLabel htmlFor={`trip_stops[${index}][time]`}>{t('PriceCalc.DepatureTime')}</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopTimePicker
								id={`trip_stops[${index}][time]`}
								name={`trip_stops[${index}][time]`}
								error={Boolean(touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].time && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].time)}
								slotProps={{
									textField: {
										helperText: touched.trip_stops && touched.trip_stops[index] && touched.trip_stops[index].time && errors.trip_stops && errors.trip_stops[index] && errors.trip_stops[index].time
									},
								}}
								onChange={(value) => {
									updateTripTime(dayjs(value), 'time');
									/* setFieldValue(`trip_stops[${index}][time]`, dayjs(value), true);
									setDepatureDateTime(dayjs(values.trip_stops[index].date).format('YYYY-MM-DD') + ' ' + dayjs(value).format('HH:mm'));
									if ((index + 1) < values.trip_stops.length) {
										for (let i = index + 1; i < values.trip_stops.length; i++) {
											setFieldValue(`trip_stops[${i}].duration`, values.trip_stops[i]['duration']);

											setFieldValue(`trip_stops[${i}].arrival`, addDurationToTime(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), values.trip_stops[i]['duration']));

											setFieldValue(`trip_stops[${i}].next_depature`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), values.trip_stops[i]['duration']));

											setFieldValue(`trip_stops[${i}].date`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), duration));

											setFieldValue(`trip_stops[${i}].time`, roundToNearest15MinutesAfterAddingDuration(dayjs(values.trip_stops[i].date).format('YYYY-MM-DD') + ' ' + dayjs(values.trip_stops[i].time).format('HH:mm'), duration));
										}
									} */
									// handleGlobalChange();
								}}
								onBlur={handleBlur}
								minTime={(index > 0 && dayjs(values.trip_stops[index]['date']).isSame(dayjs(values.trip_stops[index - 1]['next_depature']), 'day')) ? dayjs(values.trip_stops[index - 1]['next_depature']) : false}
								value={dayjs(values.trip_stops[index]['time'])}
								ampm={false}
								timeSteps={{ minutes: 15 }}
								thresholdToRenderTimeInASingleColumn={96}
							/>
						</LocalizationProvider>
					</TimeSelect>
				</RightPart>
				<LeftPart></LeftPart>
				{values.trip_stops[index]['duration'] > 0 && <RightPart>
					{/* <Stack>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>
							{`Duration : ${values.trip_stops[index]['duration']}`}
						</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>
							{`Duration Time : ${convertSecondsToHoursAndMinutesWithDayjs(values.trip_stops[index]['duration']).hours} hours and ${convertSecondsToHoursAndMinutesWithDayjs(values.trip_stops[index]['duration']).minutes} minutes`}
						</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{'Arrival: '}{dayjs(values.trip_stops[index]['arrival']).format('YYYY-MM-DD HH:mm')}</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{'Next Depature: '}{values.trip_stops[index]['next_depature']}</Typography>
						<Typography component='h4' sx={{ paddingTop: '15px' }}>{t('PriceCalc.ExpectedArrival', { time: dayjs(values.trip_stops[index]['arrival']).format('HH:mm'), date: dayjs(values.trip_stops[index]['arrival']).format('DD/MM/YYYY') })}</Typography>
					</Stack> */}
					<Typography component='h4' sx={{ paddingTop: '15px' }}>{t('PriceCalc.ExpectedArrival', { time: dayjs(values.trip_stops[index]['arrival']).format('HH:mm'), date: dayjs(values.trip_stops[index]['arrival']).format('DD/MM/YYYY') })}</Typography>
				</RightPart>}
			</TripFeild>
		</TripBox>
	);
};

export default TripBoxComponent;
