import React, { useEffect } from 'react';
import { t } from 'i18next';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Container, Divider, FormGroup, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import BusPartnerHeaderComponent from '../../components/HeaderComponent/buspartner';
import { getOfferDetail, submitSupplierOffer } from '../../redux/reqhistory';
import { RightArrow } from '../../assets/icon';
import { FlashContent, Heading, ItineraryTitle, StatisticsBlock, FormBox, PartnerFormWrapp, SubmitButton, SubmitWrapper, TermsConditions, DetailsBox, FormInputGroup } from './styled';
import { getStopSequence } from '../../utils/helper';
import BusPartnerFooterComponent from '../../components/FooterComponent/buspartner';

const SupplierOffer = () => {
	const { tripCode, busPartnerCode } = useParams();
	const { loading, offerDetail, submitOffer } = useSelector(state => state.reqhistory);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (!isEmpty(tripCode)) {
			dispatch(getOfferDetail({ trip_request_code: tripCode, unique_code: busPartnerCode }));
		}
	}, [tripCode]);

	useEffect(() => {
		if (!isEmpty(submitOffer)) {
			navigate(`/${busPartnerCode}/offer-submit-sucess`);
		}

	}, [submitOffer]);

	const initialValues = {
		supplier_name: '',
		supplier_telephone_number: '',
		supplier_email: '',
		offer_amount: '',
	};
	const validationSchema = Yup.object().shape({
		supplier_name: Yup.string().required('Required'),
		supplier_telephone_number: Yup.string().required('Required'),
		supplier_email: Yup.string().required('Required').email(),
		offer_amount: Yup.string().required('Required'),
	});
	const handleUpdateConfig = (values, { resetForm }) => {
		const payload = {
			...values,
			supplier_telephone_number: values['supplier_telephone_number'].toString(),
			trip_request_code: tripCode,
			unique_code: busPartnerCode
		};
		dispatch(submitSupplierOffer(payload));
		resetForm();
	};
	return (
		<>
			<BusPartnerHeaderComponent />
			<Container maxWidth='md'>
				<Heading>
					{t('Offer.Title')}
				</Heading>
				<DetailsBox>
					<ItineraryTitle>{t('Offer.Itinerary')}: <span>{offerDetail?.customer_segments && getStopSequence(offerDetail?.customer_segments)}</span></ItineraryTitle>
					<Stack
						divider={<Divider orientation="vertical" flexItem className="customDivider" />}
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						alignItems="center"
						justifyContent="space-between">
						<StatisticsBlock>
							<Typography variant='h5'>{tripCode}</Typography>
							<Typography variant='span'>{t('Offer.RequestCode')}</Typography>
						</StatisticsBlock>
						<StatisticsBlock>
							<Typography variant='h5'>{offerDetail?.departure_date}</Typography>
							<Typography variant='span'>{t('Offer.DepatureDate')}</Typography>
						</StatisticsBlock>
						<StatisticsBlock>
							<Typography variant='h5'>{offerDetail?.arrival_date}</Typography>
							<Typography variant='span'>{t('Offer.ArrivalDate')}</Typography>
						</StatisticsBlock>
						<StatisticsBlock>
							<Typography variant='h5'>{offerDetail?.passengers}</Typography>
							<Typography variant='span'>{t('Offer.Pax')}</Typography>
						</StatisticsBlock>
						<StatisticsBlock>
							<Typography variant='h5'>{offerDetail?.tot_empty_km} KM</Typography>
							<Typography variant='span'>{t('Offer.TotalKM')}</Typography>
						</StatisticsBlock>
					</Stack>
				</DetailsBox>
				<DetailsBox>
					<FlashContent>
						<Typography variant="h4">{t('Offer.Table')} <span>{t('Offer.CustTrip')}</span></Typography>
						<TableContainer component={Paper} sx={{ maxWidth: '950px', boxShadow: 'none', borderRadius: 0, }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="center">{t('Offer.Stop')}</TableCell>
										<TableCell align="center">{t('Offer.From')}</TableCell>
										<TableCell align="center">{t('Offer.To')}</TableCell>
										<TableCell align="center">{t('Offer.DepatureDateTime')}</TableCell>
										<TableCell align="center">{t('Offer.ArrivalDateTime')}*</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{offerDetail?.customer_segments && offerDetail?.customer_segments.map((seg, index) => (
										<TableRow key={index}>
											<TableCell align="center">{index + 1}</TableCell>
											<TableCell align="center"><span>{seg.departure_stop.address}</span></TableCell>
											<TableCell align="center"><span>{seg.destination_stop.address}</span></TableCell>
											<TableCell align="center"><strong>{seg.departure_stop.departure_datetime}</strong></TableCell>
											<TableCell align="center"><strong>{seg.destination_stop.arrival_datetime}</strong></TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</FlashContent>
				</DetailsBox>
				<DetailsBox>
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUpdateConfig}>
						{({ values, touched, errors, /* setFieldValue, */ handleChange, handleSubmit, handleBlur/* , submitForm */ }) => (
							<Form noValidate onSubmit={handleSubmit} autoComplete='off'>
								<FormBox>
									<PartnerFormWrapp >
										<Typography variant="h4">{t('Offer.Form')}</Typography>
										<FormGroup row className='formWrapp' >
											<FormInputGroup style={{ width: '25%' }}>
												<Typography>{t('Offer.Name')}</Typography>
												<TextField
													placeholder={t('Offer.Name')}
													error={!!(touched.supplier_name && errors.supplier_name)}
													helperText={touched.supplier_name && errors.supplier_name}
													type='text'
													name='supplier_name'
													onChange={handleChange}
													onBlur={handleBlur}
													value={values?.supplier_name}
												/>
											</FormInputGroup>
											<FormInputGroup style={{ width: '25%' }}>
												<Typography>{t('Offer.Telephone')}</Typography>
												<TextField
													placeholder={t('Offer.Telephone')}
													error={!!(touched.supplier_telephone_number && errors.supplier_telephone_number)}
													helperText={touched.supplier_telephone_number && errors.supplier_telephone_number}
													type='number'
													name='supplier_telephone_number'
													onChange={handleChange}
													onBlur={handleBlur}
													value={values?.supplier_telephone_number}
													onKeyDown={(e) => {
														if (e.key === 'e' || e.key === 'E' || e.key === '-') {
															e.preventDefault();
														}
													}}
												/>
											</FormInputGroup>
											<FormInputGroup style={{ width: '50%' }}>
												<Typography>{t('Offer.Email')}</Typography>
												<TextField
													placeholder={t('Offer.Email')}
													error={!!(touched.supplier_email && errors.supplier_email)}
													helperText={touched.supplier_email && errors.supplier_email}
													type='email'
													name='supplier_email'
													onChange={handleChange}
													onBlur={handleBlur}
													value={values?.supplier_email}
												/>
											</FormInputGroup>
										</FormGroup>
										<FormGroup row className='formWrapp' >
											<FormInputGroup>
												<Typography>{t('Offer.Price')}</Typography>
												<TextField
													placeholder={t('Offer.Price')}
													error={!!(touched.offer_amount && errors.offer_amount)}
													helperText={touched.offer_amount && errors.offer_amount}
													type='number'
													name='offer_amount'
													onChange={handleChange}
													onBlur={handleBlur}
													value={values?.offer_amount}
													onKeyDown={(e) => {
														if (e.key === 'e' || e.key === 'E' || e.key === '+') {
															e.preventDefault();
														}
													}}
												/>
											</FormInputGroup>
										</FormGroup>
										<Typography variant="h4">{t('Offer.AdditionalNote')}</Typography>
										<FormGroup row>
											<TextField
												fullWidth
												multiline
												rows={3}
												name='additional_info'
												placeholder={t('Offer.AdditionalInfoPlaceholder')}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values?.additional_info}
											/>
										</FormGroup>
									</PartnerFormWrapp>
									<SubmitWrapper>
										<SubmitButton>
											{loading && <CircularProgress color='warning' size={24} thickness={5} />}
											<Button type='submit' variant='contained' disabled={loading}>{t('Offer.Submit')}<RightArrow /></Button>
										</SubmitButton>
										<TermsConditions>
											<Typography>
												{t('Offer.AcceptPPTC').split('[PrivacyPolicy]').map((part, index) =>
													index !== 0 ?
														<React.Fragment key={index}>
															<Link target='_blank' href='privacy-policy' color="primary" rel='noopener noreferrer'>
																{t('Offer.PrivacyPolicy')}
															</Link>
															{part.split('[TermsConditions]').map((part2, index2) =>
																index2 !== 0 ?
																	<React.Fragment key={index2}>
																		<Link target='_blank' href='terms-conditions' color="primary" rel='noopener noreferrer'>{t('Offer.TermsConditions')}</Link>
																		{part2}
																	</React.Fragment>
																	: part2
															)}
														</React.Fragment>
														: part
												)}
											</Typography>
										</TermsConditions>
									</SubmitWrapper>
								</FormBox>
							</Form>
						)}
					</Formik>
				</DetailsBox>
			</Container >
			<BusPartnerFooterComponent />
		</>
	);
};

export default SupplierOffer;
