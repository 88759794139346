import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { Typography, Container, Button, Modal, Grid, Switch, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../assets/icon';
import { changeStripeStatus, createConnectLink, getUserDetails, paymentTermsConditionAccept } from '../../redux/auth';
import { resetAllErrors, resetConnectLink, resetPaymentTerms } from '../../redux/auth/slice';
import { HeadPart, StripBox, PaymentInfoBox, EnableStripeButton, CompleteButton, CloseButton, ModalContent, ModalContentData, ModalHeader, GreenButton, CustomSwitch, CurrentDue } from './styled';
import dayjs from 'dayjs';

const StripeAccount = () => {
	const { busPartner, paymentTermsAccept, connectLink, changeStripeStatusError, connectLinkError, userDetailsError, paymentTermsAcceptError, connectLinkloading, changeStripeStatusFlag } = useSelector(state => state.auth);
	const [confirmTerms, setConfirmTerms] = useState(false);
	const [open, setOpen] = useState(false);
	const [alertMsg, setAlertMsg] = useState({
		msg: '',
		type: 'success'
	});
	const dispatch = useDispatch();
	const handleCloseModal = () => {
		setConfirmTerms(false);
	};
	const handleAcceptTermsConditions = () => {
		dispatch(paymentTermsConditionAccept());
	};

	useEffect(() => {
		dispatch(getUserDetails());
		return () => {
			dispatch(resetConnectLink());
			dispatch(resetPaymentTerms());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(paymentTermsAccept)) {
			dispatch(getUserDetails());
		}
	}, [paymentTermsAccept]);

	useEffect(() => {
		if (!isEmpty(connectLink) && connectLink?.link) {
			window.open(connectLink.link, '_blank');
			dispatch(resetConnectLink());
		}
	}, [connectLink]);

	useEffect(() => {
		if (!isEmpty(changeStripeStatusError)) {
			setAlertMsg({
				msg: changeStripeStatusError,
				type: 'error'
			});
			setOpen(true);
			dispatch(resetAllErrors());
		}
		if (!isEmpty(connectLinkError)) {
			setAlertMsg({
				msg: connectLinkError,
				type: 'error'
			});
			setOpen(true);
			dispatch(resetAllErrors());
		}
		if (!isEmpty(userDetailsError)) {
			setAlertMsg({
				msg: userDetailsError,
				type: 'error'
			});
			setOpen(true);
			dispatch(resetAllErrors());
		}
		if (!isEmpty(paymentTermsAcceptError)) {
			setAlertMsg({
				msg: paymentTermsAcceptError,
				type: 'error'
			});
			setOpen(true);
			dispatch(resetAllErrors());
		}
		if (changeStripeStatusFlag !== null) {
			setAlertMsg({
				msg: changeStripeStatusFlag ? t('StripeAccount.PaymentSuccess') : t('StripeAccount.PaymentCancel'),
				type: 'success'
			});
			setOpen(true);
			dispatch(resetAllErrors());
		}
	}, [changeStripeStatusError, connectLinkError, userDetailsError, paymentTermsAcceptError, changeStripeStatusFlag]);

	const generateStripeLink = () => {
		dispatch(createConnectLink());
	};

	const redirectToStripe = () => {
		window.open('https://dashboard.stripe.com/login', '_blank');
	};

	const handleChangeStripePaymentsStatus = (event) => {
		dispatch(changeStripeStatus({ stripe_payments_active: event.target.checked }));
	};
	return (
		<>
			<HeadPart>
				<Typography component="h2">{t('StripeAccount.Title')}</Typography>
				{busPartner && busPartner?.payment_terms_conditions_accepted &&
					<CustomSwitch>
						<Switch
							focusVisibleClassName=".Mui-focusVisible"
							disableRipple
							checked={busPartner?.stripe_payments_active}
							disabled={
								!(
									busPartner?.stripe_charges_enabled &&
									busPartner?.stripe_payout_enabled &&
									busPartner?.stripe_onboarding_complete
								)
							}
							onChange={handleChangeStripePaymentsStatus}
						/>
					</CustomSwitch>
				}
			</HeadPart>
			{busPartner && !(busPartner?.payment_terms_conditions_accepted) ?
				<StripBox>
					<Container maxWidth='md'>
						<Typography variant="h6">{t('StripeAccount.Description')}</Typography>
						<EnableStripeButton>
							<Button variant='contained' onClick={() => setConfirmTerms(true)} >
								{t('StripeAccount.EnableStripBtn')}
							</Button>
						</EnableStripeButton>
					</Container>
					{/* Start Accept Payment Terms & Condition Modal */}
					<Modal
						open={confirmTerms}
						onClose={handleCloseModal}
						aria-labelledby='accept-payment-terms-conditions'
						aria-describedby='accept-payment-terms-conditions-body'>
						<ModalContent>
							<ModalHeader>
								<Typography id='accept-payment-terms-conditions' variant='h3'>{t('StripeAccount.Modal.Title')}</Typography>
								<CloseButton onClick={handleCloseModal}><CloseIcon /></CloseButton>
							</ModalHeader>
							<ModalContentData id='accept-payment-terms-conditions-body'>
								<Typography id='accept-payment-terms-conditions-description' variant='span'>
									{t('StripeAccount.Modal.Description')}
								</Typography>
								<GreenButton>
									<Button onClick={handleAcceptTermsConditions} variant='contained'>{t('StripeAccount.Modal.SubmitBtn')}</Button>
								</GreenButton>
							</ModalContentData>
						</ModalContent>
					</Modal>
					{/* End Accept Payment Terms & Condition Modal */}
				</StripBox> :
				<PaymentInfoBox>
					<Grid container spacing={2}>
						<Grid item xs={6} md={3}>
							<Typography variant="h6">{t('StripeAccount.Charges')}</Typography>
							<Typography variant="span">{busPartner?.stripe_charges_enabled ? t('StripeAccount.Qualified') : t('StripeAccount.NotQualified')}</Typography>
						</Grid>
						<Grid item xs={6} md={3}>
							<Typography variant="h6">{t('StripeAccount.Payments')}</Typography>
							<Typography variant="span">{busPartner?.stripe_payout_enabled ? t('StripeAccount.Qualified') : t('StripeAccount.NotQualified')}</Typography>
						</Grid>
						<Grid item xs={6} md={3}>
							<Typography variant="h6">{t('StripeAccount.Onboarding')}</Typography>
							<Typography variant="span">{busPartner?.stripe_onboarding_complete ? t('StripeAccount.Complete') : t('StripeAccount.Incomplete')}</Typography>
						</Grid>
						<Grid item xs={6} md={3}>
							<CompleteButton>
								{connectLinkloading && <CircularProgress color='warning' size={24} thickness={5} sx={{ marginRight: '10px' }} />}
								{(
									busPartner?.stripe_charges_enabled &&
									busPartner?.stripe_payout_enabled &&
									busPartner?.stripe_onboarding_complete
								) ?
									<Box style={{ textAlign: 'center' }}>
										<Button variant='contained' onClick={redirectToStripe}>
											{t('StripeAccount.StripeAccount')}
										</Button>
										{busPartner?.stripe_current_deadline && <CurrentDue>
											{t('StripeAccount.CurrentlyDue', { date : dayjs(busPartner?.stripe_current_deadline).format('DD/MM/YYYY') })}
										</CurrentDue>}
									</Box>
									:
									<Button
										variant='contained'
										onClick={generateStripeLink}
										disabled={connectLinkloading}>
										{t('StripeAccount.CompleteNowBtn')}
									</Button>
								}
							</CompleteButton>
						</Grid>
					</Grid>
				</PaymentInfoBox>}
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
				<Alert severity={alertMsg.type} sx={{ width: '100%' }}>
					{alertMsg.msg}
				</Alert>
			</Snackbar>
		</>
	);
};

export default StripeAccount;
