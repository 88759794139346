import React from 'react';
import { Header, HeaderLogo } from './styled';
import { ReactComponent as Logo } from '../../../src/assets/icon/logo.svg';
import { useNavigate } from 'react-router-dom';
const HeaderComponent = () => {
	const navigate = useNavigate();
	return (
		<Header onClick={() => navigate('/')}>
			<HeaderLogo>
				<Logo />
			</HeaderLogo>
		</Header>
	);
};

export default HeaderComponent;
